@font-face {
  font-family: Viga;
  src: url("./fonts/Viga-Regular.ttf");
}
body {
  margin: 0;
}

.App-logo{
  height: 5rem;
  pointer-events: none;
}

.logolink{
  margin-left:1rem;
  margin-top: .5rem;
}

.App-header {
  background-image: url("./images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  max-height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.togglebutton{
  height: 5rem;
  width: 5rem;
  cursor: pointer;
  display: none;
}
.svg-inline--fa{
  height: 5rem !important;
  width: 2rem !important;
}

h1{
  font-size: calc(5vw + 3rem);
  font-family: Viga;
  text-transform: capitalize;
  line-height:0.8;
  margin-block-start: 0;
  margin-block-end: 0;
}

h2{
  font-size: 2.813rem;
}

h3{
  font-size: 2.188rem;
}

h4{
  font-size: 1.563rem;
}

.navbar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;  
  position: relative;
}

.links{
  display: flex;
  flex-direction: row;
  margin: 1rem; 
  width: 40%;
  position: relative;
  justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

.merchslider{
display: flex;
flex-direction: row;
}

a{
  font-family: Viga;
  color: aliceblue;
  flex-direction: row;
  flex: auto;
  text-decoration: none;
  font-size: 1rem;  
}

.horizontallife{
  display: flex;  
  width: 100vw;
  height: 100vh;
  align-items: start;  
}

.content{
  max-width: 100vw;
  overflow: hidden;
}
.hero{
  display: grid;  
  grid-template-columns: auto auto auto;  
  height: 75%;   
}
.herotitleholder{
  display: flex;
  flex-direction: column;  
  text-align: left; 
  margin-left: 10rem;
  margin-top: 2.5rem;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1; 
  color: white;  
}
.herotitleholdermerch{
  display: flex;
  flex-direction: column;  
  text-align: left; 
  margin-left: 10rem;
  margin-top: 10rem;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1; 
  color: white;  
}
.subtitleholder{
  width: calc(5vw + 3rem);
  height: calc(5vw + 3rem);
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
}
.subtitle{
color: white;
font-family: Viga;
width: calc(12vw + 10rem);;
justify-content: center;
font-size: calc(.5vw + .5rem);
line-height: 1;
margin-block-start: 0;
margin-block-end: 0;
}
.merchitem{
display: flex;
flex-direction: column;
width:23rem;
height:15rem;
color: white;
font-family: Viga;
text-align: center;
}
.merchtitle{
  font-size: 2rem;
}
.merchcontainer{
  display: flex;
  flex-direction: row;
  margin-right: 10rem;
  margin-left: 10rem;
  margin-top: 5rem;
}
.merchdescription{
  margin-top: 1rem;
  font-size: calc(.5vw + .5rem);
  line-height: 1;
}
.heroimage1{
  display: flex;
  margin-top: -5rem;
}
.item2{
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  margin-top: 5rem;
}
.item3{
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}

.aboutuscontentholder{
  display: flex;
  flex-direction: column;
  margin-right: 10rem;
  margin-left: 10rem;
  margin-top: 5rem;
}
.aboutustext{
  color: white;
  font-family: Viga;
  margin-top: 1rem;
  font-size: calc(.5vw + .5rem);
  line-height: 1;
}
.aboutustitle{
  color: white;
  font-family: Viga;
  font-size: 2rem;
}
.slider{  
  display: flex;
  width: calc(50vw + 150em);
  height: 100vh;
  align-items: start;
  justify-content: left;
  align-content: flex-start;  
}
.wrapper{
display: grid;
align-items: center;
justify-content: center;
height: 100vh;
grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1
}

.box{
background-image: url('./images/pumpkin.png');
-webkit-background-size:cover;
background-size: cover;
background-position: center center;
height: 250px;
width: 250px;
box-shadow:  0 20px 5px 5px rgba(0,0,0,0.2);
animation: animate 5s ease-in-out infinite;
transition: all 1s ease-in-out;
grid-column-start: 1;
grid-column-end: 1;
}

@keyframes animate {
  0%{
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  25%{
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  50%{
    border-radius: 40% 20% 50% 60%/70% 35% 55% 35%;
  }

  75%{
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }

  100%{
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}
.subtitleholdermobile{
display: none;
}
.gooey-image {
  filter: url(#goo);
  max-width: 100%;
  width: 500px;
  }

/* Define the SVG filter */
svg {
  width: 0;
  height: 0;
  position: absolute;
}
svg defs {
  filter: url(#goo);
}
.herocontent{
  width:100vw;
  height:100vh;
}

.gooey-container {
  position: absolute;
  overflow: hidden;
  width: 400px;
  height: 400px;
  z-index: 1;  
}

.canvascontainer{ 
  display: flex; 
  grid-column-start: 2;
  grid-column-end: 2;
  width:700px;
  height:700px;
}

  canvas{
    display: block;    
    filter: url(#gooeysvg);
    align-items: center;
    justify-content: center;
    filter: brightness(50%);
    transition: ease-in-out 1s;         
  }

  canvas:hover{
    filter: brightness(100%);
  }


/* width */
::-webkit-scrollbar {
  width: calc(.5vw + .5rem);
}

/* Track */
::-webkit-scrollbar-track {  
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: purple;  
  border-radius: 10px;
}

.cartdetailsholder{
display: flex;
flex-direction: column;
height: 80vh;
overflow-y: scroll;
overflow-x: hidden;
}
.totalcheckoutsection{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.total{
  color: white;
  font-family: Viga;
  font-size: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  
}
.ordertotal{
  color: white;
  font-family: Viga;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  
}
.Coffeework{
  display:flex !important;
  margin-bottom: 7rem;
}
.Trashcan{
  position: relative !important;
  width: 1rem !important;
  height: 1rem !important;
}
/*.totaldata{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}*/
.totalsummaryholder{
  display: flex;
  flex-direction: column;
  background-color:rgba(117,21,117,.52) ;
  border-radius: 45px;
  height: 15rem;
  width: 20rem;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  margin-top: 2rem;
  transition: .75s ease-in-out;
  pointer-events: none;
}
.totallabelholder{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;  
}
.totalsholder {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  height: 40%;
  padding:2rem;
  margin-top: 2rem;  
}

  .cartholder {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
}

  .cartnewslidercontent{
    display: flex;
    align-items: center;
    justify-content: center; 
  }
  .newslidercontent{
    display: flex;  
    width: 100vw;
    height: 100vh;
    align-items: start;  
  }
  .checkoutnewslidercontent{
    display: flex;  
    width: 100vw;
    height: 100vh;
    align-items: start;  
  }
  .productnewslidercontent{
    display: flex;  
    width: 100vw;
    height: 100vh;
    align-items: start;  
  }

.overlapcontent{
  position:absolute;
  z-index: 3;
  margin-top: 20rem;

}

.checkoutpageholder{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
.internaltitletext{ 
 color: white;
 font-size: 2rem; 
 font-family: Viga;  
}

.internalsubtitletext{  
  color: white;
  font-size: 1rem; 
  font-family: Viga;    
}
.canvasholder{
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.canvasholder1{
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.canvasholder2{
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}


.descriptorholder{
  z-index: 5;
  position: absolute;  
  text-align: right;
  align-items: flex-end;
  justify-content: right;
  width: 20rem;
  top:5%;
  left:20%;
  opacity: 0%;
  transition: ease-in-out 1s;  
}

.canvasholder:hover > .descriptorholder{  
  opacity: 100%;  
}

.canvasholder1:hover > .descriptorholder{  
  opacity: 100%;  
}
.canvasholder2:hover > .descriptorholder{  
  opacity: 100%;  
}

.overlapcontent:hover > .descriptorholder{  
  opacity: 100%;  
}

.Homecanvas:hover > .descriptorholder{
opacity: 100%
}
.Homecanvas:hover > .descriptorholder1{
  opacity: 100%
  }

  .Homecanvas:hover > .descriptorholder2{
    opacity: 100%
    }
h2 span{
  background-color: black;
}
h3 span{
  background-color: black;
}

p span{
  background-color: black;
}
.workcontainer{
  display: grid;
  grid-template-columns: auto auto;
  width: 100vw;
  height: 100vh;
  align-content: space-evenly;
  justify-content: space-evenly

}

.halloweenrightsection{
  margin-bottom: 9rem !important;
}
.workcanvas{
  filter: brightness(50%);
  transition: ease-in-out 1s;    
}
.workcanvas:hover{
  filter: brightness(50%);
}

.Workmaintitle{
 color: white;
 font-size: 3.5rem; 
 font-family: Viga; 
}
.Workmainpara{
  color: white;
  font-size: 1.1rem; 
  font-family: Viga; 
}
.workcontentholder{
  display: table;
  position: relative;
  margin-left: 4rem;
  max-width: fit-content;
  width: 45%;
  max-height: 100vh;
}
.overlaytextholder{
  z-index: 5;
  position: absolute;
  width: 32rem;
}
.rightcontentsection{
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-evenly;
  justify-items: stretch;
  margin-bottom: 5rem;   
}
.coffeeworkrightsection{
  align-content: center;
  grid-gap: 2rem;
}
.workcontenttheway1{
  display: flex;
  height: calc(5vh + 5rem);
  grid-column: span 3;
  grid-row-start: 1;
  grid-row-end: 1;
  justify-content: space-evenly
}

.workcontenttheway2{
  display: flex;
  height: calc(14vh + 9rem);
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;
  justify-content: space-evenly 
}
.workcontenttheway3{
  display: flex;
  height: calc(14vh + 9rem);
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 2;
  justify-content: space-evenly 
}
.workcontenttheway4{
  display: flex;
  height: calc(14vh + 9rem);
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 2;
  justify-content: space-evenly 
}

.workcontenttheway5{
  display: flex;
  height: calc(5vh + 5rem);
  grid-column: span 3;
  grid-row-start: 3;
  grid-row-end: 3;
  justify-content: space-evenly 
}
.workimgtheway{
  display: flex;   
  object-fit: cover;
  filter: brightness(100%);
  transition: ease-in-out 1s; 
}

/* .workimgtheway:hover{
  filter: brightness(100%);
  scale: 1.1;

} */

.workimgtheway1{
  display: flex;   
  object-fit: cover;
  width: 100%;
  transition: ease-in-out 1s;  
}

.workcanvasholder{
  display: table;
  justify-content: space-evenly;
  max-width: fit-content;
}
.arrowsection{
  display: flex;
  flex-direction: row;
  max-height: 2rem;
  grid-column: span 3;
  grid-row-start: 4;
  grid-row-end: 4;
  justify-content: flex-end;
  overflow: hidden;
}
.transcontent{
grid-gap: 2rem;
}
.Arrowimages{
  margin-right: 1rem; 
 /* -webkit-animation: nextprojectarrow 2s ease-in-out forwards; 
  animation: nextprojectarrow 2s ease-in-out forwards;*/
}

 /*
.arrowsection .Arrowimages:nth-child(1) {
  animation-delay: 2s;
}

.arrowsection .Arrowimages:nth-child(2) {
  animation-delay: 1s;
}

@keyframes nextprojectarrow {
  0%{
    margin-top: 100px;
    opacity: 0%;
  }
  100%{
    margin-top: 0px;
    opacity: 100%;
  }
}

.arrowsection:hover > .Arrowimages{
transform: scaleX(110%);
}
*/

.rightcontentsectiontrans{
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-evenly;
  justify-items: stretch;
  margin-bottom: 5rem;
  width: 45%;
  max-height: 100vh;  
}

.workimgtrans1{
  display: flex;
  height: 100%;
  grid-column: span 1;
  grid-row: span 2;
  justify-content: flex-start
}
.workimgtrans2{
  display: flex;
  height: 100%;
  grid-column: 3 / span 1;;
  grid-row: span 2;
  justify-content: flex-end
}
.workimgtrans3{
  display: flex;
  height: 10rem;
  grid-column: span 3;
  grid-row: span 1;
  justify-content: space-evenly
}

.arrowsectiontrans{
  display: flex;
  flex-direction: row;
  max-height: 2rem;
  grid-column: span 3;
  grid-row: span 1;
  justify-content: flex-end;
  overflow: hidden;
}
.Workmaintitle1{
  color: white;
 font-size: 3.5rem; 
 font-family: Viga
}
.Workmaintitle2{
  color: white;
 font-size: 3.5rem; 
 font-family: Viga;
 margin-top: 10rem;  
}

.buttonholder{
  display: flex;
  position: relative;
  width: calc(12vw + 10rem);
  height: 3rem;
  overflow: hidden;
  border-radius: 1rem;
  transition: all 0.4s;
}


.buttonholder .CTAbutton{
  height:100%;
  width:100%;
  border: none;
  outline: none;
  background:none;
  color: white;
  font-size: calc(.75vw + .5rem);
  cursor:pointer;
  font-family: Viga;
}


.buttonholder .buttoninner{
position: absolute;
width: 300%;
height: 100%;
left:-100%;
background: -webkit-linear-gradient(right, #12ECB8, #F803A4, #12ECB8, #F803A4);
z-index: -1;
transition: all 0.4s;
}
.buttonholder:hover {
  transform: scale(1.05);
}
.buttonholder:hover .buttoninner{
  left:0;
}

.checkoutbuttonholderinner{
  display: flex;
  position: relative;
  width: calc(9vw + 9rem);
  height: 3rem;
  overflow: hidden;
  border-radius: 1rem;
  transition: all 0.4s;
  pointer-events: auto;

}

.checkoutbuttonholderinner .CTAbutton{
  height:100%;
  width:100%;
  border: none;
  outline: none;
  background:none;
  color: white;
  font-size: calc(.75vw + .5rem);
  cursor:pointer;
  font-family: Viga;
  pointer-events: auto;
}


.checkoutbuttonholderinner .buttoninner{
position: absolute;
width: 300%;
height: 100%;
left:-100%;
background: -webkit-linear-gradient(right, #12ECB8, #F803A4, #12ECB8, #F803A4);
z-index: -1;
transition: all 0.4s;
}
.checkoutbuttonholderinner:hover {
  transform: scale(1.05);
}
.checkoutbuttonholderinner:hover .buttoninner{
  left:0;
}

.totalsummaryholder:hover {
  transform: scale(1.05);
}

.addtocartbuttonholder{
  display: flex;
  position: relative;
  width: calc(6vw + 5rem);
  height: 3rem;
  overflow: hidden;
  border-radius: 1rem;
  transition: all 0.4s;
}


.addtocartbuttonholder .addtocartCTAbutton{
  height:100%;
  width:100%;
  border: none;
  outline: none;
  background:none;
  color: white;
  font-size: calc(.75vw + .5rem);
  cursor:pointer;
  font-family: Viga;
}


.addtocartbuttonholder .buttoninner{
position: absolute;
width: 300%;
height: 100%;
left:-100%;
background: -webkit-linear-gradient(right, #12ECB8, #F803A4, #12ECB8, #F803A4);
z-index: -1;
transition: all 0.4s;
}
.addtocartbuttonholder:hover {
  transform: scale(1.05);
}
.addtocartbuttonholder:hover .buttoninner{
  left:0;
}


/* Halloween page */

.workimghalloween1{
  display: flex;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  width: calc(15vw + 5rem);
  justify-content: space-around;
  align-content: space-around
}

.workimghalloween2{
  display: flex;   
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;
  width: calc(15vw + 5rem);
  justify-content: space-around;
  align-content: space-around

}

.workimghalloween3{
  display: flex;    
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
  width: calc(15vw + 5rem);
  justify-content: space-around;
  align-content: space-around
 
}

.workimghalloween4{
  display: flex;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 3;
  width: calc(15vw + 5rem);
  justify-content: space-around;
  align-content: space-around
   
}

.workimghalloween5{
  display: flex;   
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 4;
  grid-row-end: 4;
  width: calc(15vw + 5rem);
  justify-content: space-around;
  align-content: space-around
 
}

.workimghalloween6{
  display: flex;  
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 5;
  justify-content: space-around;
  width: calc(15vw + 5rem);
  align-content: space-around  
}

.workimghalloween{
  display: flex;   
  object-fit: cover;  
  transition: ease-in-out 1s;
  padding: 1rem;
  width: calc(15vw + 5rem);  
}
.arrowsectionhalloween{
  display: flex;
  flex-direction: row;
  max-height: 2rem;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 6;
  grid-row-end: 6;
  justify-content: flex-end;
  overflow: hidden;
}
.externalicon{
  position: relative;  
  height: 100%;
  width: 100%;  
}
.svg-inline--fa{
  position: absolute;
  z-index: 10;
  height: 1rem;
  width: 1rem;
  top: 0;
}
.overlapundercontent{
    position:absolute;
    z-index: 5;
    top: 10rem;
    left:20rem;
     
}
.secondcontentholder{
  position: relative;
}

.thirdcontentholder{
  position: relative;
}

.descriptorholder2{
  z-index: 5;
  position: absolute;  
  text-align: right;
  align-items: flex-end;
  justify-content: right;
  width: 20rem;
  top:5%;
  left:20%; 
  opacity: 0%;
  transition: ease-in-out 1s;  
}

.canvasholder:hover > .descriptorholder2{  
  opacity: 100%;  
}

.overlapundercontent:hover > .descriptorholder2{  
  opacity: 100%;  
}

.overlapundercontent1{
  position:absolute;
  z-index: 6;
  top:-5rem;  
  left:48rem;
}

.descriptorholder3{
  z-index: 6;
  position: absolute;  
  text-align: right;
  align-items: flex-end;
  justify-content: right;
  width: 20rem;
  top:15%;
  left:20%; 
  opacity: 0%;
  transition: ease-in-out 1s;  
}

.canvasholder:hover > .descriptorholder3{  
  opacity: 100%;  
}

.canvasholder1:hover > .descriptorholder3{  
  opacity: 100%;  
}

.canvasholder2:hover > .descriptorholder3{  
  opacity: 100%;  
}

.overlapundercontent1:hover > .descriptorholder3{  
  opacity: 100%;  
}

.overlapundercontent2{
  position:absolute;
  z-index: 5;
  top:5rem;  
  left:55rem;

}

.descriptorholder4{
  z-index: 7;
  position: absolute;  
  text-align: right;
  align-items: flex-end;
  justify-content: right;
  width: 20rem;
  top:25%;
  left:30%; 
  opacity: 0%;
  transition: ease-in-out 1s;  
}

.canvasholder:hover > .descriptorholder4{  
  opacity: 100%;  
}

.overlapundercontent2:hover > .descriptorholder4{  
  opacity: 100%;  
}

.overlapundercontent3{
  position:absolute;
  z-index: 4;
  top:0rem;  
  left:90rem;

}
.instalink{
  text-decoration: none;
}
.instagramlink{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 360%;
  background-color: white;
  width: 5rem;
  height: 5rem;
  transition: all .75s ease-in-out;
  text-decoration: none;  
}

.instagramlink:hover {
  background-color: purple;
  animation: vibrate 150ms linear infinite forwards;  
}

.instagramlink:hover > .svg-inline--fa{
  transition: all .75s ease-in-out;
  animation: vibrate 150ms linear infinite forwards;
  color: white;
}

.instalink:hover > .svg-inline--fa{
color: white;
}

.bewlogoholder{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 360%;
  background-color: black;
  width: 5rem;
  height: 5rem;
  transition: all .75s ease-in-out;  
}

.bewlogoimg1{
  position: absolute;
  opacity: 1;
  width: 3rem;
  height: 3rem;
  transition: all .75s ease-in-out; 
}
.bewlogoimg2{
  position: absolute;
  opacity: 0;
  width: 3rem;
  height: 3rem;
  transition: all .75s ease-in-out; 
}
.bewlogoholder:hover{
  background-color: white;
  scale: 1.1;
}
.bewlogoholder:hover > .bewlogoimg1{
  opacity: 0;
}

.bewlogoholder:hover > .bewlogoimg2{
  opacity: 1;
}
.bewlogoimg1:hover{
  opacity: 0;
}
.bewlogoimg2:hover {
  opacity: 1;
}
.overlapundercontent4{
  position:absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  top:0rem;  
  left:135rem;
  height: 75%;
}
.sociallinks{
  padding-right: 2rem;
  padding-left: 2rem;
}
.descriptorholder5{
  z-index: 7;
  position: absolute;  
  text-align: right;
  align-items: flex-end;
  justify-content: right;
  width: 20rem;
  top:25%;
  left:30%; 
  opacity: 0%;
  transition: ease-in-out 1s;  
}

.canvasholder:hover > .descriptorholder5{  
  opacity: 100%;  
}

.canvasholder1:hover > .descriptorholder5{  
  opacity: 100%;  
}

.canvasholder2:hover > .descriptorholder5{  
  opacity: 100%;  
}


.overlapundercontent3:hover > .descriptorholder5{  
  opacity: 100%;  
}


/* Tara page */
.workimgtara1{
  display: flex;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;

}

.workimgtara2{
  display: flex;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;

}

.workimgtara3{
  display: flex;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 4;

}

.workimgtara4{
  display: flex;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 3;

}
.producttpagemainimg{
  width: 30rem;
}

.producttitle{
  color: white;
 font-size: 3.5rem; 
 font-family: Viga
}


.cartitemtitle{
  color: white;
  font-size: 1.2rem; 
  font-family: Viga
 }

 .orderitemtitle{
  color: white;
  font-size: 1rem; 
  font-family: Viga
 }
.cartitemcolor{
  padding-right:.5rem
}

.cartitemsizeholder{
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    border: solid white;
    border-width: 2px;
    border-radius: .25rem;
    justify-content: center;
    align-items: center;
    color: white;
  
}
.orderitemsizeholder{
  display: flex;
  width: 1rem;
  height: 1rem;
  border: solid white;
  border-width: 2px;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  color: white;

}
.productdescriptionholder{
  margin-top: 2rem;
  width: calc(20vw + 20rem);
  font-size: calc(.5vw + .5rem);
  line-height: 1;
  color: white; 
  font-family: Viga
}

.cartitemdescription{
  display: flex;
  flex-direction: column;
  margin-top: .5rem;
  font-size: calc(.5vw + .5rem);
  line-height: 1;
  color: white; 
  font-family: Viga;
  align-content: space-around;  
}

.orderitemdescription{
  display: flex;
  flex-direction: column;
  margin-top: .5rem;
  font-size: calc(.5vw + .25rem);
  line-height: 1;
  color: white; 
  font-family: Viga;
  align-content: space-around;  
}
.cartiteminfo{
  padding: 1rem;
}
.productcolourpicker{
  width: 30rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.colourname{
  margin-top: .5rem;
  font-size: calc(.5vw + .5rem);
  line-height: 1;
  color: white; 
  font-family: Viga
}
.productsectiontitle{
  margin-top: 3rem;
  font-size: calc(1vw + 1rem);
  line-height: 1;
  color: white; 
  font-family: Viga
}


.totalproductsectiontitle{
  margin-top: 1.5rem;
  font-size: calc(1vw + 1rem);
  line-height: 1;
  color: white; 
  font-family: Viga; 
  text-align: right;
}
.cartproductsectiontitle{
  margin-top: 1.5rem;
  font-size: calc(1vw + 1rem);
  line-height: 1;
  color: white; 
  font-family: Viga;
  margin-left:2rem;
  
}

.ordersummaryproductsectiontitle{
  font-size: calc(1vw + 1rem);
  line-height: 1;
  color: white; 
  font-family: Viga;
  margin-bottom: 2rem;  
}

.productsectiontitleleft{
  padding-bottom: .5rem;
  font-size: calc(1vw + 1rem);
  line-height: 1;
  color: white; 
  font-family: Viga
}
.shippinginfo{
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 30%; 
}
.shippingwarning{
  padding-bottom: .5rem;
  color: white; 
  font-family: Viga
}
.priceholder{
  margin-top: 15%;
}
.shoppingbag{
position: relative;
width: 1.5rem !important;
height: 1.5rem !important;
margin-right: auto;
margin-left: auto;
margin-top: auto;
margin-bottom: auto;
}
.shoppingbagholder{
  display: flex;
  flex-direction: row;
}
.quantityindicator{
  position: absolute;
  display: flex;
  color: white; 
  font-family: Viga;
  background-color: purple;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 360%;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  margin-left: 3rem;
}
.price{
  margin-top: 1rem;
  padding-bottom: .5rem;
  color: white; 
  font-family: Viga
}
.productcolour{
  display: flex; 
  width: 5rem;
  height: 5rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.productcolourimage{
  width: 4rem;
  padding: .5rem;
  border: solid white;
  border-width: 2px;
  border-radius: 1rem;
}
.Cartitemimage{
  width: 3.5rem;
  height: 3.5rem;
  padding: .5rem;
  border: solid white;
  border-width: 2px;
  border-radius: 1rem;
}

.Orderitemimage{
  width: 2rem;
  height: 2rem;
  padding: .5rem;
  border: solid white;
  border-width: 2px;
  border-radius: 1rem;
}
.cartitemprice{
  margin-top: .5rem;
  font-size: 1.2rem;
}
.orderitemprice{
  font-size: calc(.5vw + .5rem);
  margin-left: 1rem;
}
.productsizepicker{
  width: 30rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  justify-content:space-around;
}

.productsizeholder{
  display: flex;
  width: 5rem;
  height: 5rem;
  border: solid white;
  border-width: 2px;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  color: white;
}
.productsize{
  font-size: calc(1vw + .5rem);
  line-height: 1;
  color: white; 
  font-family: Viga
}
.baglink{
  position: relative;
  z-index: 10000;
}
.baglinkmobile{
display: none;
}
.colorandsizesection{

}
.cartitemcolorandsizeholder{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-around;
}
.shippingandnumberofitems{
  display: flex;   
  margin-left: 5rem;
  flex-direction: column;
  justify-content: center;
  
}
.productcolumnsection{
  display: flex;
  flex-direction: row;
}

.purchasebuttonholder{
  display: flex;
  margin-top: auto;
}
.checkoutbuttonholder{
  display: flex;
}
.YourCart{
  display: table;
  flex-direction: column;
  align-content: center;
  justify-content: space-around;
  height: 40%;  
  padding:1rem; 
  width: 90vw;  
}

.orderdetailsholder{
  height: 20rem;
  overflow: scroll;
}

.cartitemsection{
display: flex;
flex-direction: row;
justify-content: center;
align-content: center;
align-items: center;
}

.Ordercartitemsection{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  }

.mobilecartitemsection{
  display: none;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  }
.quantitytitle{
  font-size: 1.25rem;
  line-height: 1;
  color: white; 
  font-family: Viga
}
.quantityincreaser{
 display: flex;
 flex-direction: column;
justify-content: center;
align-items: center;
align-content: center;
margin-left: 3rem;
margin-right: 3rem;
flex-wrap: wrap;
}

.checkoutquantityincreaser{
  display: flex;
  flex-direction: column;
 justify-content: center;
 align-items: center;
 align-content: center;
 margin-left: 3rem;
 margin-right: 3rem;
 flex-wrap: wrap;
 }
.addandreducebuttonholder{
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  color: white;
  font-family: Viga;
}

.orderquantityholder{
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  color: white;
  font-family: Viga;
}
.removeitembuttonholder{ 
  display: flex;
  flex-direction: column;
 justify-content: center;
 align-items: center;
 align-content: center;
 margin-left: 5rem;
 margin-right: 5rem;
 flex-wrap: wrap;
 }
.addreducebutton{
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  background-color: purple;
  border-radius: .25rem;
  margin-left: 1rem;
  margin-right: 1rem;
  justify-content: center;
  color: white;
}
.removebutton{
  background-color: darkred;
  font-size: 1rem;
  color: white;
  font-family: Viga;
  border-radius: .5rem;
  padding: .5rem;

}
.Cartitem{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: .5vh; 
  align-items: center; 
}
.workimgtara{
  display: flex;   
  object-fit: cover;  
  transition: ease-in-out 1s;
  padding: 1rem;  
}
.rightcontentsectiontara{
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-evenly;
  justify-items: stretch;
  margin-bottom: 9rem; 
  width: 100%;  
}

.linknext{
  display: flex;
  justify-content: flex-end;
}

.productcolorchangerbutton{
  background-color: rgba(255, 0, 0, 0.0);
  border: none;
}
.productcolorchangerbutton:active .productcolourimage{
background-color: white;
}
.formholder{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100vw;
position: absolute;
  z-index: 15;
  height: 75vh;
  top: 0;  
}
.shippingformholder{
  display: flex;
  flex-direction: column;
  justify-content: center;  
  position: absolute;
    z-index: 15;
    height: 75vh;
    top: 0; 
    width: 100%; 
  }
.paypalbuttonholders{
  width: 60%;
  margin-bottom: 2rem;
}
.checkoutcontactustitle{
  font-size: calc(.75vw + 1rem);
  margin-bottom: 1rem;
}
.purchasemodalbackdrop{
  position:absolute;
  width:100vw;
  height: 100vh;
  z-index: 100000;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: rgba(0,0,0,.3);

}
.lonely{
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
}
.lonelymessage{
  font-size: calc(3vw + 1rem);
  color: white;
  font-family: Viga;
  text-align: center;
}
.secondarylonelymessage{
  font-size: calc(1vw + .5rem);
  padding:2rem;
  color: white;
  font-family: Viga;
  text-align: center;
}
.purchasemodal{
background-color: white;
height: 20rem;
width: 50rem;
display: flex;
border-radius: 2rem;
justify-content: center;
align-items: center;
align-content: center;
flex-direction: column;
}
.purchasemodaltitle{
font-size: calc(2vw + 1rem);
font-family: Viga;
text-align: center;

}
.purchasethankyounote{
text-align: center;
padding: 2rem;
font-family: Viga;
}
.purchasemodalbuttonholder{
display: flex;
width: 15rem;
justify-content: space-around;
}
.purchasemodalbutton{
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width:20rem;
  height:3rem;
  border-radius: 2rem;
  background-color: purple;
  color: white;
  font-family: Viga;
  font-size: calc(.75vw + .5rem);
}
  .paymentformholder{
    display: flex;
    flex-direction: column;
    justify-content: center;  
    position: absolute;
      z-index: 15;
      height: 60vh;
      top: 0; 
      width: 60%; 
    }
.postalandstateholder{
 display: flex;
 justify-content: space-around;
 margin-top: 1rem;
 margin-bottom: 1rem;
}
.checkoutNameholder{

}
.checkoutfieldholder{
  display: flex;
  justify-content: space-between;
  margin-top: .25rem;
  margin-bottom: .25rem;
 }
.citylabel{
  margin-left: 1rem;
  margin-right: 1rem;
}
.Postallabel{
  margin-left: 0rem;
  margin-right: 0rem;
}

.checkoutcitylabel{ 
  margin-right: 1rem;
}
.checkoutPostallabel{
  margin-right: 1rem;
}

form{
  display: flex;
  flex-direction: column;
  color: white;
  font-size: calc(.75vw + .5rem);
  font-family: Viga;
  height: 75vh;
  justify-content: space-evenly;
  position: absolute;
  z-index: 15;
  top: 3rem;    
}


.Optionselection{
  font-size: calc(.5vw + .5rem);
  font-family: Viga;
  width: 65%;
}

.buttonholder1{
  display: flex;
  position: relative;
  width: calc(12vw + 10rem);
  height: 3rem;
  overflow: hidden;
  border-radius: 1rem;
  transition: all 0.4s;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
}

.buttonholder1 .CTAbutton{
  height:100%;
  width:100%;
  border: none;
  outline: none;
  background:none;
  color: white;
  font-size: calc(.75vw + .5rem);
  cursor:pointer;
  font-family: Viga;
  justify-content: center;
  align-items: center;
}


.buttonholder1 .buttoninner{
position: absolute;
width: 300%;
height: 100%;
left:-100%;
background: -webkit-linear-gradient(right, #12ECB8, #F803A4, #12ECB8, #F803A4);
z-index: -1;
transition: all 0.4s;
}
.buttonholder1:hover {
  transform: scale(1.05);
}
.buttonholder1:hover .buttoninner{
  left:0;
}

.contactuscontentholder{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  color: white;
  font-size: calc(.75vw + .5rem);
  font-family: Viga;

}

.shippinginfocontentholder{
  position: relative;
  display: flex;
  flex-direction: column; 
  color: white;
  font-size: calc(.75vw + .5rem);
  font-family: Viga;
  width: 50%;
}

.checkoutinfocontentholder{
  position: relative;
  display: flex;
  flex-direction: column; 
  color: white;
  font-size: calc(.75vw + .5rem);
  font-family: Viga;
  width: 50%;
  height: 40rem;
  justify-content: space-between;
}
.orderinformationholder{
  padding-right: 2rem;
}

.ordertotalcalculated{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 2px white solid;
}
.contactustitle{
  font-size: calc(.75vw + 1rem)
}

.radiobutton{
  font-size: calc(.5vw + .5rem);
}
.contactcanvas{
margin-top: -2rem;
}
.outerbuttonholder{
  display:flex;
  position: relative;
  justify-content: center;
  align-items: center;

}

.formquestionitem{
  display: flex;
  justify-content: space-between;
  }
.shipforminput{
  width: 65%;
}
.shippingmain{
  display: flex;
  column-count: 2;
  padding: 2rem;
  justify-content: center;
  align-content: center;
  width: 100%;
  column-gap: 10rem;
}
.externalicon:hover .fa-arrow-up-right-from-square{
scale: 1.5;
cursor: pointer;
}

.soldout{
  position: absolute;
  width: auto;
  height: auto;
}

.merchimg{
  display: flex;
  position: relative;
  justify-content: center;
}

#halloween{
  width: calc(10vw + 10rem);
}

#starwars{
  width: calc(20vw + 17rem);
}

#trans{
  width: calc(20vw + 17rem);
}

#scream{
  width: calc(25vw + 20rem);
}

#bikini{
  width: calc(15vw + 10rem);
}


#tara{
  width: calc(20vw + 17rem);
}

#angels{
    width: calc(25vw + 20rem);
}





#halloween1{
  width: calc(20vw + 17rem);
}

#starwars1{
  width: calc(20vw + 17rem);
}

#trans1{
  width: calc(20vw + 17rem);
}

#scream1{
  width: calc(25vw + 20rem);
}

#bikini1{
  width: calc(15vw + 10rem);
}


#tara1{
  width: calc(20vw + 17rem);
}

#angels1{
    width: calc(25vw + 20rem);
}

.mobilesubtitle{
  display: none;
  justify-content: center;
  align-items: center;
    }

.subtitle{
      display: flex;
    }

.titleholder{
display: flex;
flex-direction: column;
    }

.titleholdermobile{
display: none;
text-align: center;
align-items: center;
}

.dropdownmenu{
  display: none;
  justify-content: center;
  align-items: center;
  position:absolute;
  width: 0vw;
  height: 0vh;  
  border-radius: 1rem;
  overflow: hidden;
  flex-direction: column;
  z-index: 20;
  transition: .1s ease-in-out;
  opacity: 0;  
}
.hamburgermenu{
  z-index: 50;
}
.dropdownmenu a{
  font-size: 3rem;
  color: white;
  font-family: Viga;
}

.dropdownmenulink a{
  opacity: 0;
}
.dropdownmenu.open{
  width: 100vw;
  height: 100vh;
  opacity: 1;  
}
.dropdownmenu.open .dropdownmenulink.link1 a{
 opacity: 0;
 animation: staggerlinks ease-in-out 0.4s forwards 1;
 animation-delay: 1s;
}
.dropdownmenu.open .dropdownmenulink.link1{
  animation: translatey ease-in-out 0.4s forwards 1;
  animation-delay: 1s;
 }
.dropdownmenu.open .dropdownmenulink.link2 a{
  opacity: 0;
  animation: staggerlinks ease-in-out 0.4s forwards 1;
  animation-delay: 2s;
 }
 .dropdownmenu.open .dropdownmenulink.link2{
  animation: translatey ease-in-out 0.4s forwards 1;
  animation-delay: 2s;
 }
 .dropdownmenu.open .dropdownmenulink.link3 a{
  opacity: 0;
  animation: staggerlinks ease-in-out 0.4s forwards 1;
  animation-delay: 3s;
 }
 .dropdownmenu.open .dropdownmenulink.link3{
  animation: translatey ease-in-out 0.4s forwards 1;
  animation-delay: 3s;
 }
 .dropdownmenu.open .dropdownmenulink.link4 a{
  opacity: 0;
  animation: staggerlinks ease-in-out 0.4s forwards 1;
  animation-delay: 4s;
 }
 .dropdownmenu.open .dropdownmenulink.link4{
  animation: translatey ease-in-out 0.4s forwards 1;
  animation-delay: 4s;
 }
 .dropdownmenu.open .dropdownmenulink.link5 a{
  opacity: 0;
  animation: staggerlinks ease-in-out 0.4s forwards 1;
  animation-delay: 5s;
 }
 .dropdownmenu.open .dropdownmenulink.link5{
  animation: translatey ease-in-out 0.4s forwards 1;
  animation-delay: 5s;
 }
    
@keyframes staggerlinks{
  from{
    opacity: 0;
    
      }
  to{
    opacity: 1;
        
  }
}

@keyframes translatey{
  0%{
    transform: translateY(20rem);
    filter:blur(1.5rem);    
      }
  50%{
    transform: translateY(0rem);
    filter:blur(0rem);
    
  }
  55%{
    transform: translateX(-1px) translateY(1px);
  }
  65%{
    transform: translateX(1px) translateY(-2px);
  }
  75%{
    transform: translateX(-1px) translateY(1px);
  }
  85%{
    transform: translateX(1px) translateY(-2px);
  }
  95%{
    transform: translateX(-1px) translateY(1px);
  }
  100%{
    transform: translateX(1px) translateY(-2px);
  }
}

.links a:hover{
  filter: blur(3px);
  animation: vibrate 150ms linear infinite forwards;
}

@keyframes vibrate{
  0% {
      transform: translateX(-1px) translateY(1px);
      filter: blur(0px);
  }
  100% {
      transform: translateX(1px) translateY(-2px);
      filter: blur(3px);
  }
}

.navcanvas{
  position:absolute;
  width: 7rem;
  height: 7rem;
  right: 0;
  top: 5;
  z-index: 10;
  opacity: 0;
}

#dark.expand{
  height: 100vh;
  width: 100vw; 
}

/*@keyframes rotatingdark{
  from{
    height: 0vh;
    width: 0vw;      
      }
  to{
    height: 100vh;
    width: 100vw;    
   
  }
}*/

#dark.expand > .dropdownmenu{
  height: 100vh;
  width: 100vw;

}
.Yourbagtitlemobile{
  display: none;
}
.formquestionsection{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
 
}














/* IPAD  Pro */

@media(min-width: 821px) and (max-width:1279px)  {
  .Coffeework{
    margin-bottom: 0rem;
  }
  .baglinkmobile{
display: block;
  }
  .formquestionitem{
    display: flex;
    justify-content: space-between;
    width:25rem
  }
.finalcheckoutdetails{
  width: 100%;
  align-items: center;
}
.checkoutNameholder{
  font-size: 1.5rem;
}
.paypalbuttonholders{
  margin-top: 24rem;
}

.paymentformholder{
justify-content: flex-start;
margin-top: 5rem;
}

   .shippingpostal{
    margin-left: 0rem;
    margin-right: 0rem
  }
  .shippingcity{
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .checkoutform{
    width: 90vw;
    height:45vh;    
  }
  .formquestionsection{
    width: 100%;
flex-direction: row;
margin-right: auto;
margin-left: auto;
justify-content: space-around;
  }
  .checkoutmaincontainer{
overflow: scroll;
  }
  .checkoutnewslidercontent{
    height: 200vh;
  }
  .Orderitemimage{
    width: 3rem;
    height: 3rem;
  }
  .orderitemtitle{
    font-size: 1.5rem; 
  }
  .checkoutcartItemholder{
    width:50%;
  }
  .Yourorder{
    width: 100%;
  }
  .cartitemsection{
    justify-content: flex-start;
    width: 100%;
  }
  .Ordercartitemsection{
    justify-content: flex-start;
    width: 100%;
  }
  .Yourbagtitlemobile{
    display: flex;
    font-size: calc(2vw + 1rem);
    margin-top: 2rem;
  }
  .shippingmain{
    flex-direction: column;
    width: 90%;
  }
  .orderinformationholder{
    padding-right: 0rem;
    padding-bottom: 5rem;
  }

  .finalcheckout{
    padding-bottom: 3rem !important;
  }
  .orderdetailsholder{
    height: 20rem;    
  }
  .cartdetailsholder{
    height: 90vh;
  }
  .addreducebutton{
    width: 2rem;
    height: 2.2rem;
    font-size: 1.2rem;   
    align-items: center;
      }
      .removebutton{
        margin-top: 1.5rem;
      }
      .total{
        margin-top: 1.5rem;
      }
   .totalproductsectiontitle{
    font-size: calc(2vw + 1rem);
   }   
  .itemquantitydisplay{
    font-size: 1.2rem;
  }
  .YourCart{
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }
  .baglink{
    z-index: 10;
  }
  .Yourbagtitle{
    display: none;
  }
  .shoppingbag{
    height:5.5rem !important;
    width: 2rem !important;
  }
  .Cartitemimage{

    width: 5.5rem;
    height: 5.5rem;
  }
.totaldata{
  display: flex;
  column-span: all;
  justify-content: center;
}
  .quantityindicator{
    margin-top: 2rem;
    margin-left: 13rem;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
  }
  .producttpagemainimg{ 
    width: 20rem;
  }
.cartitemtitle{
  font-size: 1.5rem;
}
.quantityincreaser{
  margin-left: 1rem;
  margin-right: 1rem;
}
.checkoutquantityincreaser{
  margin-left: 8rem;
  margin-right: 15rem;
}
.cartitemcolor{
  font-size: 1.2rem;
}
.orderitemprice{
  font-size: 1.2rem;
}
.orderitemsizeholder{
  width:1.5rem;
  height:1.5rem;
}
.cartitemsizeholder{
  width: 2rem;
  height: 2rem;
}
.cartitemsize{
  font-size: 1rem;
}

.cartitemprice{
  font-size: 1.5rem;
}
.Trashcan{
  top:0rem !important
}
  .heroimage1{
    display: flex;
    margin-top: 0rem;
  }
  
  .productcolumnsection{
    display: flex;
    flex-direction: column;
  }
  
  .shippingandnumberofitems{
    display: flex;   
    margin-left: 0rem;
    flex-direction: column;
    justify-content: center;
    
  }

  .shippinginfo{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 5%; 
  }
  .priceholder{
    margin-top: 5%;
  }

  .price{
    margin-top: 1rem;
    padding-bottom: .5rem;
    color: white; 
    font-family: Viga;
    font-size: calc(1rem + 1vw)
  }

  .purchasebuttonholder{
    display: flex;
    margin-top: auto;
    margin-top: 5%;
  }
  .overlapundercontent4{
    position:absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
    top:70rem;  
    left:50rem;
    height: auto;   
  }

  .instagramlink{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 360%;
    background-color: purple;
    width: 5rem;
    height: 5rem;
    transition: all .75s ease-in-out;
    text-decoration: none;  
  }
  
 .instaicon{
  margin-bottom: 10px;
  width: 2rem !important;
  height: 2rem !important;
  color: white !important;
 }  
  .merchslider{
    flex-direction: column;
    width: 100vw;
    height:220%;
    align-items: center;  
    justify-content: flex-start;
    }
    .links{
      display: none;
    }
.togglebutton{
  display: flex;
  cursor: pointer;
}
.dropdownmenu{
  display: flex;
}
.subtitleholdermobile{
  display: flex;
  }
.logolink{
  margin-left:1rem;
  margin-top: 1rem;
}
.navcanvas{
  opacity: 1;
}
.slider{
  flex-direction: column;
  width: 100vw;
  height: 130%;
  align-items: center;  
  justify-content: flex-start;
}

.scroll-horizontal{
  display: flex;
  flex-direction: column;
  overflow: auto !important;
  overflow-x: hidden !important; 
  width: 100vw !important;
  height: 250% !important;
}

  .secondcontentholder{
    display: flex;
    flex-direction: column;
    flex: wrap;
    width: 100vw !important;
  }

  .overlapundercontent{
    top:2rem;
    left: 25rem;
  }
  .overlapundercontent1{
left: 5rem;
top:30rem
  }
  .overlapundercontent2{
    left: 25rem;
top:30rem
  }
  .overlapundercontent3{
    left: 25%;
    top:50rem
  }
  .rightcontentsectiontrans.halloweenrightsection{
    margin-bottom: 7rem !important;
  }
.canvasholder2{
    left: -10rem
  }
  .newslidercontent{
  overflow: hidden;
  }
  .productnewslidercontent{
    overflow: hidden;
    flex-direction: row;
    justify-content: flex-start;    
    margin-top: 6rem;
    }
  .maincontainer{
    overflow: hidden;
  }

  .canvasholder1{
    top:2rem;
    left: 10rem;
  }

  .overlapcontent{
    top:25rem;
    left: 10rem; 
  }
  .mobilesubtitle{
display: flex;
  }
  .subtitle{
    display: none;
  }

  .titleholder{
    display: none;
        }
        
    .titleholdermobile{
    display: flex;
    justify-content: center;
    align-items: center;
    } 

.herotitleholder{
  display: flex;
  margin-left: 0;
  margin-top: 10rem;
  justify-content: center;
  align-items: center;
}

.subtitleholder{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;  
}
.workcontainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1{
  font-size: calc(3vw + 3rem);
}
.workcanvas{
  width: 50vw !important;
}

.Workmainpara{
 width: 50rem;
 text-align: center;
}
.workcontentholder{
  display: flex;
  width: 100vw !important;
  justify-content: center;
  align-items: center;
  height: 40vh;
  margin-left: 0;
}
.workcontainer{
  justify-content: center;
  align-items: center;
}

.overlaytextholder{
  display: flex;
  width: 100vw !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.workcontenttheway1{
  height: calc(3vh + 6rem);
}
.workcontenttheway5{
  height: calc(3vh + 6rem);
}
.workcontenttheway2{
  height: calc(12vh + 6rem);
}
.workcontenttheway3{
  height: calc(12vh + 6rem);
}
.workcontenttheway4{
  height: calc(12vh + 6rem);
}
.Workmaintitle{
font-size: 5rem;
text-align: center;
}
.Workmaintitle1{
font-size: 5rem;
text-align: center;
}
.workimghalloween{
width: calc(20vw + 15rem);
}
.rightcontentsectiontrans{
width: 100%;
grid-row-gap: calc(.5vh + .5rem);
}
.transcontent{
  margin-top: 3rem;
  justify-content: center !important;
  }

.rightcontentsection{
  grid-row-gap: calc(1vh + .5rem);

}
h2{
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
.workimgtrans1{
  justify-content: space-evenly;
}

.workimgtrans2{
  justify-content: space-evenly;  
}

.workimgtara1{
  justify-content: center;
  align-items: center;
}


.workimgtara{
  width: calc(10vw + 9rem);
}

.workimgtara2{
  justify-content: flex-end;
}
.workimgtara3{
  justify-content: flex-start;
}
.workimgtara4{
  justify-content: flex-end;
}

.taraeyes{
  width: 75%;
}

.Workmaintitle2{
  font-size: 5rem;
  text-align: center;  
}
.merchcontainer{
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.herotitleholdermerch{
  margin-left: 0;
  margin-top: 0;
}
.subtitlemobile{
  display: flex;
  font-size: 1.5rem;
  text-align: center;
  justify-content: center;
  font-family:Viga;
  width: 50rem;
}
.item2{
  margin-right: 0;
  margin-left: 0;
  margin-top: 20rem;
}
.item3 {
  margin-right: 0;
  margin-left: 0;
  margin-top: 15rem;
}
.merchdescription{
  font-size: 1.5rem;
}

/* about us page */

.newslidercontent{
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
}


.aboutustitle{
font-size: calc(3vw + 3rem);
text-align: center;
}
.aboutustext{
  font-size: 1.3rem;
}
.aboutuscontentholder{
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 2rem;
}
.checkoutshippinginformationtitle{
  width: 50rem;
  text-align: left !important;
}
/* Contact us page */

.contactustitle{
  font-size: calc(2vw + 2rem);
  text-align: center;
}
label{
  font-size: 1.5rem;
}
.radiobutton{
  font-size: 1.1rem;
}
input{
  font-size: 1.1rem;
}
.Optionselection{
  font-size: 1.1rem;
  width: 14rem;
}
.textarea{
  font-size: 1.1rem;
}
.CTAbutton{
  font-size: 1.5rem !important;
}

.buttonholder1{
width: calc(15vw + 12rem)
}

.buttonholder{
  width: calc(12vw + 18rem)
}
.svg-inline--fa{
  top: 1.5rem;
}
/* width */
::-webkit-scrollbar {
  display:none;
}

/* Track */
::-webkit-scrollbar-track {  
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: purple;  
  border-radius: 10px;
}

.togglebutton{
  position:absolute;
  right:0;
}
.dropdownmenu.open{
  display: flex;
}
}
















/* IPAD  Air */

@media(min-width: 820px) and (max-width:821px)  {
  .baglinkmobile{
    display: block;
    right: 5rem;
  }
  .checkoutfieldsection{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

  }

  .checkoutshippinginformationtitle{
    font-size: 2rem !important;
  }
  .formquestionlabel{
  font-size: 1.25rem;
  }
  .Postallabel{
    font-size: 1.25rem;
  }
  .citylabel{
    font-size: 1.25rem;
  }
  .formquestionitem{
    display: flex;
    justify-content: space-between;
    width:21rem
  }
.finalcheckoutdetails{
  width: 100%;
  align-items: center;
}
.checkoutNameholder{
  font-size: 1.5rem;
}
.paypalbuttonholders{
  margin-top: 0rem;
}

.paymentformholder{
justify-content: flex-start;
margin-top: 5rem;
width: 90%;
height: 0rem;
}
.checkoutinfocontentholder{
height:37rem
}
.checkoutfieldholder{
  width: 35vw;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
   .shippingpostal{
    margin-left: 0rem;
    margin-right: 0rem
  }
  .shippingcity{
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .checkoutform{
    width: 90vw;
    height:45vh;    
  }
  .formquestionsection{
    width: 100%;
flex-direction: row;
margin-right: auto;
margin-left: auto;
justify-content: space-around;
  }
  .checkoutmaincontainer{
overflow: scroll;
  }
  .checkoutnewslidercontent{
    height: 200vh;
  }
  .Orderitemimage{
    width: 3rem;
    height: 3rem;
  }
  .orderitemtitle{
    font-size: 1.5rem; 
  }
  .checkoutcartItemholder{
    width:50%;
  }
  .Yourorder{
    width: 100%;
  }
  .cartitemsection{
    justify-content: flex-start;
    width: 90vw;
  }
  .Ordercartitemsection{
    justify-content: flex-start;
    width: 100%;
  }
  .Yourbagtitlemobile{
    display: flex;
    font-size: calc(2vw + 1rem);
    margin-top: 2rem;
  }
  .shippingmain{
    flex-direction: column;
    width: 90%;
  }
  .orderinformationholder{
    padding-right: 0rem;
    padding-bottom: 2rem;
  }
  .shippinginfocontentholder{
    width:90vw;
  }
  .finalcheckout{
    padding-bottom: 2rem !important;
  }
  .orderdetailsholder{
    height: 20rem;    
  }
  .cartdetailsholder{
    height: 90vh;
  }
  .addreducebutton{
    width: 2rem;
    height: 2.2rem;
    font-size: 1.2rem;   
    align-items: center;
      }
      .removebutton{
        margin-top: 1.5rem;
      }
      .total{
        margin-top: 1.5rem;
      }
   .totalproductsectiontitle{
    font-size: calc(2vw + 1rem);
   }   
  .itemquantitydisplay{
    font-size: 1.2rem;
    display: flex;
    align-items: center;
  }
  .YourCart{
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }
  .baglink{
    z-index: 10;
  }
  .Yourbagtitle{
    display: none;
  }
  .shoppingbag{
    height:5.5rem !important;
    width: 2rem !important;
  }
  .Cartitemimage{

    width: 5.5rem;
    height: 5.5rem;
  }
.totaldata{
  display: flex;
  column-span: all;
  justify-content: center;
}
  .quantityindicator{
    margin-top: 2rem;
    margin-left: 10rem;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
  }
  .producttpagemainimg{ 
    width: 20rem;
  }
.cartitemtitle{
  font-size: 1.25rem;
}
.removeitembuttonholder{
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.quantityincreaser{
  margin-left: 1rem;
  margin-right: 1rem;
}
.checkoutquantityincreaser{
  margin-left: 4rem;
  margin-right: 13rem;
}
.cartitemcolor{
  font-size: 1.2rem;
}
.orderitemprice{
  font-size: 1.2rem;
}
.orderitemsizeholder{
  width:1.5rem;
  height:1.5rem;
}
.cartitemsizeholder{
  width: 2rem;
  height: 2rem;
}
.cartitemsize{
  font-size: 1rem;
}

.cartitemprice{
  font-size: 1.5rem;
}
.Trashcan{
  top:0rem !important
}
  .heroimage1{
    display: flex;
    margin-top: 0rem;
  }
  
  .productcolumnsection{
    display: flex;
    flex-direction: column;
  }
  
  .shippingandnumberofitems{
    display: flex;   
    margin-left: 0rem;
    flex-direction: column;
    justify-content: center;
    
  }

  .shippinginfo{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 5%; 
  }
  .priceholder{
    margin-top: 5%;
  }

  .price{
    margin-top: 1rem;
    padding-bottom: .5rem;
    color: white; 
    font-family: Viga;
    font-size: calc(1rem + 1vw)
  }

  .purchasebuttonholder{
    display: flex;
    margin-top: auto;
    margin-top: 5%;
  }
  .overlapundercontent4{
    position:absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 4;
    top:68rem;  
    left:36rem;
    height: auto;   
  }

  .instagramlink{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 360%;
    background-color: purple;
    width: 5rem;
    height: 5rem;
    transition: all .75s ease-in-out;
    text-decoration: none;  
  }
  
 .instaicon{
  margin-bottom: 10px;
  width: 2rem !important;
  height: 2rem !important;
  color: white !important;
 }  
  .merchslider{
    flex-direction: column;
    width: 100vw;
    height:220%;
    align-items: center;  
    justify-content: flex-start;
    }
    .links{
      display: none;
    }
.togglebutton{
  display: flex;
  cursor: pointer;
}
.dropdownmenu{
  display: flex;
}
.subtitleholdermobile{
  display: flex;
  }
.logolink{
  margin-left:1rem;
  margin-top: 1rem;
}
.navcanvas{
  opacity: 1;
}
.slider{
  flex-direction: column;
  width: 100vw;
  height: 130%;
  align-items: center;  
  justify-content: flex-start;
}

.scroll-horizontal{
  display: flex;
  flex-direction: column;
  overflow: auto !important;
  overflow-x: hidden !important; 
  width: 100vw !important;
  height: 250% !important;
}

  .secondcontentholder{
    display: flex;
    flex-direction: column;
    flex: wrap;
    width: 100vw !important;
  }

  .overlapundercontent{
    top:2rem;
    left: 25rem;
  }
  .overlapundercontent1{
left: 5rem;
top:30rem
  }
  .overlapundercontent2{
    left: 25rem;
top:30rem
  }
  .overlapundercontent3{
    left: 25%;
    top:50rem
  }
  .rightcontentsectiontrans.halloweenrightsection{
    margin-bottom: 10rem !important;
  }
  .fightsectionright{
    margin-bottom: 10rem !important;
  }
.canvasholder2{
    left: -10rem
  }
  .newslidercontent{
  overflow: hidden;
  }
  .productnewslidercontent{
    overflow: hidden;
    flex-direction: row;
    justify-content: flex-start;    
    margin-top: 6rem;
    }
  .maincontainer{
    overflow: hidden;
  }

  .canvasholder1{
    top:2rem;
    left: 10rem;
  }

  .overlapcontent{
    top:25rem;
    left: 10rem; 
  }
  .mobilesubtitle{
display: flex;
  }
  .subtitle{
    display: none;
  }

  .titleholder{
    display: none;
        }
        
    .titleholdermobile{
    display: flex;
    justify-content: center;
    align-items: center;
    } 

.herotitleholder{
  display: flex;
  margin-left: 0;
  margin-top: 10rem;
  justify-content: center;
  align-items: center;
}

.subtitleholder{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;  
}
.workcontainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1{
  font-size: calc(3vw + 3rem);
}
.workcanvas{
  width: 50vw !important;
}

.Workmainpara{
 width: 50rem;
 text-align: center;
}
.workcontentholder{
  display: flex;
  width: 100vw !important;
  justify-content: center;
  align-items: center;
  height: 40vh;
  margin-left: 0;
}
.workcontainer{
  justify-content: center;
  align-items: center;
}

.overlaytextholder{
  display: flex;
  width: 100vw !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.workcontenttheway1{
  height: calc(3vh + 6rem);
}
.workcontenttheway5{
  height: calc(3vh + 6rem);
}
.workcontenttheway2{
  height: calc(12vh + 6rem);
}
.workcontenttheway3{
  height: calc(12vh + 6rem);
}
.workcontenttheway4{
  height: calc(12vh + 6rem);
}
.Workmaintitle{
  font-size: 5rem;
  text-align: center;
  }

.Workmaintitle1{
font-size: 5rem;
text-align: center;
}
.workimghalloween{
width: calc(20vw + 15rem);
}
.rightcontentsectiontrans{
width: 100%;
grid-row-gap: calc(.5vh + .5rem);
}
.transcontent{
  margin-top: 3rem;
  justify-content: center;
  }
.coffeeworkrightsection{
margin-bottom: 10rem !important;
}
.rightcontentsection{
  grid-row-gap: calc(1vh + .5rem);

}
h2{
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}
.workimgtrans1{
  justify-content: space-evenly;
}

.workimgtrans2{
  justify-content: space-evenly;  
}

.workimgtara1{
  justify-content: center;
  align-items: center;
}


.workimgtara{
  width: calc(10vw + 9rem);
}

.workimgtara2{
  justify-content: flex-end;
}
.workimgtara3{
  justify-content: flex-start;
}
.workimgtara4{
  justify-content: flex-end;
}

.taraeyes{
  width: 75%;
}

.Workmaintitle2{
  font-size: 5rem;
  text-align: center;  
}
.merchcontainer{
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.herotitleholdermerch{
  margin-left: 0;
  margin-top: 0;
}
.subtitlemobile{
  display: flex;
  font-size: 1.5rem;
  text-align: center;
  justify-content: center;
  font-family:Viga;
  width: 50rem;
}
.item2{
  margin-right: 0;
  margin-left: 0;
  margin-top: 20rem;
}
.item3 {
  margin-right: 0;
  margin-left: 0;
  margin-top: 15rem;
}
.merchdescription{
  font-size: 1.5rem;
}

/* about us page */

.newslidercontent{
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
}


.aboutustitle{
font-size: calc(3vw + 3rem);
text-align: center;
}
.aboutustext{
  font-size: 1.3rem;
}
.aboutuscontentholder{
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 2rem;
}

/* Contact us page */

.contactustitle{
  font-size: calc(2vw + 2rem);
  text-align: center;
}
label{
  font-size: 1.5rem;
}
.radiobutton{
  font-size: 1.1rem;
}
input{
  font-size: 1.1rem;
}
.Optionselection{
  font-size: 1.1rem;
}
.textarea{
  font-size: 1.1rem;
}
.CTAbutton{
  font-size: 1.5rem !important;
}

.buttonholder1{
width: calc(15vw + 12rem)
}

.buttonholder{
  width: calc(12vw + 18rem)
}
.svg-inline--fa{
  top: 1.5rem;
}
/* width */
::-webkit-scrollbar {
  display:none;
}

/* Track */
::-webkit-scrollbar-track {  
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: purple;  
  border-radius: 10px;
}

.togglebutton{
  position:absolute;
  right:0;
}
.Coffeework{
  margin-bottom: 0rem;
}
.dropdownmenu.open{
  display: flex;
}
}











/* IPAD mini */
@media(min-width: 768px) and (max-width:819px)  {
  .baglinkmobile{
    display: block;
  }
  .Coffeework{
    margin-bottom: 0rem;
  }
  .fightsectionright{
margin-bottom: 7rem;
  }
  .coffeeworkrightsection{
    margin-bottom: 7rem;
  }
  .cartiteminfo{
    padding:.75rem;
  }
  .postalandstateholder{
    margin-bottom: 0rem;
    margin-top: 0rem;
  }
  .checkoutfieldsection{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

  }
  .shippingformbutton{
    margin-top: 0rem;
    margin-bottom: 1rem;
  }
  .checkoutshippinginformationtitle{
    font-size: 2rem !important;
  }
  .formquestionlabel{
  font-size: 1.25rem;
  }
  .Postallabel{
    font-size: 1.25rem;
  }
  .citylabel{
    font-size: 1.25rem;
  }
  .formquestionitem{
    display: flex;
    justify-content: space-between;
    width:21rem
  }
.finalcheckoutdetails{
  width: 100%;
  align-items: center;
}
.checkoutNameholder{
  font-size: 1.5rem;
}
.paypalbuttonholders{
  margin-top: 0rem;
}

.paymentformholder{
justify-content: flex-start;
margin-top: 3rem;
width: 90%;
height: 0rem;
}
.checkoutinfocontentholder{
height:32rem
}
.checkoutfieldholder{
  width: 35vw;
  margin-top: .5rem;
  margin-bottom: .5rem;
}
   .shippingpostal{
    margin-left: 0rem;
    margin-right: 0rem
  }
  .shippingcity{
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .checkoutform{
    width: 90vw;
    height:45vh;    
  }
  .formquestionsection{
    width: 100%;
flex-direction: row;
margin-right: auto;
margin-left: auto;
justify-content: space-around;
  }
  .checkoutmaincontainer{
overflow: scroll;
  }
  .checkoutnewslidercontent{
    height: 200vh;
  }
  .Orderitemimage{
    width: 3rem;
    height: 3rem;
  }
  .orderitemtitle{
    font-size: 1.25rem; 
  }
  .checkoutcartItemholder{
    width:50%;
  }
  .Yourorder{
    width: 100%;
  }
  .cartitemsection{
    justify-content: flex-start;
    width: 90vw;
  }
  .Ordercartitemsection{
    justify-content: flex-start;
    width: 100%;
  }
  .Yourbagtitlemobile{
    display: flex;
    font-size: calc(2vw + 1rem);
    margin-top: 2rem;
  }
  .shippingmain{
    flex-direction: column;
    width: 90%;
    padding-bottom: 0rem;    
  }
  .orderinformationholder{
    padding-right: 0rem;
    padding-bottom: 2rem;
  }
  .shippinginfocontentholder{
    width:90vw;
  }
  .finalcheckout{
    padding-bottom: 1rem !important;
  }
  .orderdetailsholder{
    height: 19rem;    
  }
  .ordersummaryproductsectiontitle{
    margin-bottom:1rem
  }
  .cartdetailsholder{
    height: 90vh;
    width: 95vw;
  }
  .cartholder{
    width: 95%;
  }
  .addreducebutton{
    width: 2rem;
    height: 2.2rem;
    font-size: 1.2rem;   
    align-items: center;
      }
      .removebutton{
        margin-top: 1.5rem;
      }
      .total{
        margin-top: 1.5rem;
      }
   .totalproductsectiontitle{
    font-size: calc(1vw + 1rem);
    margin-top: 1rem;
   }   
  .itemquantitydisplay{
    font-size: 1.2rem;
    display: flex;
    align-items: center;
  }
  .YourCart{
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }
  .baglink{
    z-index: 10;
  }
  .Yourbagtitle{
    display: none;
  }
  .shoppingbag{
    height:5.5rem !important;
    width: 2rem !important;
  }
  .Cartitemimage{
    width: 3.5rem;
    height: 3.5rem;
  }
.totaldata{
  display: flex;
  column-span: all;
  justify-content: center;
}
  .quantityindicator{
    margin-top: 2rem;
    margin-left: 13rem;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
  }
  .producttpagemainimg{ 
    width: 20rem;
  }
.cartitemtitle{
  font-size: 1.25rem;
}
.removeitembuttonholder{
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.quantityincreaser{
  margin-left: 1rem;
  margin-right: 1rem;
}
.checkoutquantityincreaser{
  margin-left: 4rem;
  margin-right: 11rem;
}
.cartitemcolor{
  font-size: 1.2rem;
}
.orderitemprice{
  font-size: 1.2rem;
}
.orderitemsizeholder{
  width:1.5rem;
  height:1.5rem;
}
.cartitemsizeholder{
  width: 2rem;
  height: 2rem;
}
.cartitemsize{
  font-size: 1rem;
}

.cartitemprice{
  font-size: 1.5rem;
}
.Trashcan{
  top:0rem !important
}
  .heroimage1{
    display: flex;
    margin-top: 0rem;
  }
  
  .productcolumnsection{
    display: flex;
    flex-direction: column;
  }
  
  .shippingandnumberofitems{
    display: flex;   
    margin-left: 0rem;
    flex-direction: column;
    justify-content: center;
    
  }

  .shippinginfo{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 5%; 
  }
  .priceholder{
    margin-top: 5%;
  }

  .price{
    margin-top: 1rem;
    padding-bottom: .5rem;
    color: white; 
    font-family: Viga;
    font-size: calc(1rem + 1vw)
  }

  .purchasebuttonholder{
    display: flex;
    margin-top: auto;
    margin-top: 5%;
  }
  .togglebutton{
position: absolute;
right: 0;
  }
  .shoppingbag{
    height:5.5rem !important;
    width: 1.5rem !important;
    padding-right: 2rem !important;
  }

  .quantityindicator{
    margin-top: 2rem;
    margin-left: 8rem;
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
  }

  .merchslider{
    flex-direction: column;
    width: 100vw;
    height:470%;
    align-items: center;  
    justify-content: flex-start;
    }

    .links{
      display: none;
    }
.togglebutton{
  display: flex;
  cursor: pointer;
}
.dropdownmenu{
  display: flex;
}
.logolink{
  margin-left:1rem;
  margin-top: 1rem;
}
.subtitleholdermobile{
  display: flex;
  }
.navcanvas{
  opacity: 1;
}
  .slider{
    flex-direction: column;
    width: 100vw;
    height: 150%;
    align-items: center;  
    justify-content: flex-start;
  }

  .scroll-horizontal{
    display: flex;
    flex-direction: column;
    overflow: auto !important;
    overflow-x: hidden !important; 
    width: 100vw !important;
  }

  .secondcontentholder{
    display: flex;
    flex-direction: column;
    flex: wrap;
    width: 100vw !important;
  }

  .overlapundercontent{
    top:2rem;
    left: 25rem;
  }
  .overlapundercontent1{
left: 5rem;
top:30rem
  }
  .overlapundercontent2{
    left: 25rem;
top:30rem
  }
  .overlapundercontent3{
    left: 25%;
    top:50rem
  }

.canvasholder2{
    left: -10rem
  }
  .newslidercontent{
  overflow: hidden;
  }
  .productnewslidercontent{
    overflow: hidden;
    }
  .maincontainer{
    overflow: hidden;
  }

  .canvasholder1{
    top:2rem;
    left: 10rem;
  }

  .overlapcontent{
    top:25rem;
    left: 10rem; 
  }
  .mobilesubtitle{
display: flex;
  }
  .subtitle{
    display: none;
  }

  .titleholder{
    display: none;
        }
        
    .titleholdermobile{
    display: flex;
    justify-content: center;
    } 

    .herotitleholder{
      display: flex;
      margin-left: 0;
      margin-top: 10rem;
      justify-content: center;
      align-items: center;
    }

.subtitleholder{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-top: 2rem;  
}
.workcontainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1{
  font-size: calc(3vw + 3rem);
}
.workcanvas{
  width: 50vw !important;
}

.Workmainpara{
 width: 45rem;
 text-align: center;
}
.workcontentholder{
  display: flex;
  width: 100vw !important;
  justify-content: center;
  align-items: center;
  margin-left: 0;
}
.workcontainer{
  justify-content: center;
  align-items: center;
}

.overlaytextholder{
  display: flex;
  width: 100vw !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.workcontenttheway1{
  height: calc(3vh + 4rem);
}
.workcontenttheway5{
  height: calc(3vh + 4rem);
}
.workcontenttheway2{
  height: calc(10vh + 5rem);
}
.workcontenttheway3{
  height: calc(10vh + 5rem);
}
.workcontenttheway4{
  height: calc(10vh + 5rem);
}
.Workmaintitle1{
font-size: 3.5rem;
text-align: center;
}
.Workmaintitle{
  font-size: 3.5rem;
}
.workimghalloween{
width: calc(10vw + 10rem);
}
.rightcontentsectiontrans{
width: 75%;
}
h2{
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
}
.rightcontentsection{
  grid-row-gap: calc(1vh + .5rem);

}
.workimgtrans1{
  justify-content: space-evenly;
}

.workimgtrans2{
  justify-content: space-evenly;  
}
.rightcontentsectiontrans{
  grid-row-gap: calc(.5vh + .5rem);
}
.workimgtara{
  width: calc(10vw + 8rem);
}
.workimgtara1{
  justify-content: flex-end;
}
.workimgtara2{
  justify-content: flex-end;
}
.workimgtara3{
  justify-content: flex-start;
}
.workimgtara4{
  justify-content: flex-end;
}

.taraeyes{
  width: 65%;
}

.workimgtara1{
  justify-content: center;
  align-items: center;
}

#halloween1{
  height: 300px !important;
  }
#starwars1{
  height: 400px !important;
}  
#scream1{
  height: 430px !important;
}
#trans1{
  height: 430px !important;
}
.Workmaintitle2{
  margin-top: 3rem;
}
p{
  margin-block-start: .5em;
  margin-block-end: .5em;
}
.CTAbutton{
  font-size: 1.5rem !important;
}
.buttonholder{
  width: calc(20vw + 15rem);
}
.merchcontainer{
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.herotitleholdermerch{
  margin-left: 0;
  margin-top: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.subtitlemobile{
  display: flex;
  font-size: 1.5rem;
  text-align: center;
  justify-content: center;
  font-family:Viga;
  width: 50rem;
}
.item2{
  margin-right: 0;
  margin-left: 0;
  margin-top: 20rem;
}
.item3 {
  margin-right: 0;
  margin-left: 0;
  margin-top: 15rem;
}
.merchdescription{
  font-size: 1.5rem;
}

/* about us page */

.newslidercontent{
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
}

.productnewslidercontent{
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
}
.aboutustitle{
font-size: calc(3vw + 3rem);
text-align: center;
}
.aboutustext{
  font-size: 1.1rem;
}
.aboutuscontentholder{
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 0rem;
}

/* Contact us page */

.contactustitle{
  font-size: calc(2vw + 2rem);
  text-align: center;
}
label{
  font-size: 1.5rem;
}
.radiobutton{
  font-size: 1.1rem;
}
input{
  font-size: 1.1rem;
}
.Optionselection{
  font-size: 1.1rem;
}
.textarea{
  font-size: 1.1rem;
}
.CTAbutton{
  font-size: 1.5rem !important;
}

.buttonholder1{
width: calc(15vw + 12rem)
}
.svg-inline--fa{
  top: 1.5rem;
}

.overlapundercontent4{
  position:absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  top:68rem;  
  left:35rem;
  height: auto;   
}

.instagramlink{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 360%;
  background-color: purple;
  width: 4rem;
  height: 4rem;
  transition: all .75s ease-in-out;
  text-decoration: none;  
}

.instaicon{
margin-bottom: 10px;
width: 2rem !important;
height: 2rem !important;
color: white !important;
}  

/* width */
::-webkit-scrollbar {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {  
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: purple;  
  border-radius: 10px;
}
.dropdownmenu.open{
  display: flex;
}

}















/* Iphone  plus*/
@media(min-width:414px ) and (max-width:767px)  {
  .baglinkmobile{
    display: block;
  }

  .transcontent{
    justify-content: center;
    align-content: center;
    align-items: center;
    grid-gap:0rem;
  }


  .descriptorholder4{
    left:10%;
  }
  .descriptorholder5{
    top: 50%;
    left:-5%;
  }
  .descriptorholder2{
    left:-15%;
  }
  .descriptorholder3{
    left:-15%;
  }
  .descriptorholder{
    left: -15%
  }
  .cartiteminfo{
    padding:.75rem;
  }
  .postalandstateholder{
    margin-bottom: 0rem;
    margin-top: 0rem;
  }
  .checkoutfieldsection{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

  }
  .shippingformbutton{
    margin-top: 0rem;
    margin-bottom: 1rem;
  }
  .checkoutshippinginformationtitle{
    font-size: 1.5rem !important;
  }

  .checkoutcitylabel{
    margin-left: .5rem;
  }
  .checkoutPostallabel{
    margin-left: .5rem;
  }
  .formquestionlabel{
  font-size: .75rem;
  margin-left: .5rem;
  margin-right: .5rem;
  }
  .Postallabel{
    font-size: .75rem;
  }
  .citylabel{
    font-size: .75rem;
  }
  .formquestionitem{
    display: flex;
    justify-content: space-between;
    width:21rem;
    margin-left: .5rem;
    margin-right: .5rem;
  }
.finalcheckoutdetails{
  width: 100%;
  align-items: center;
}
.checkoutNameholder{
  font-size: .75rem;
}
.paypalbuttonholders{
  margin-top: 0rem;
}

.paymentformholder{
justify-content: flex-start;
margin-top: 3rem;
width: 90%;
height: 0rem;
}
.checkoutinfocontentholder{
height:25rem
}
.checkoutfieldholder{
  width: 45vw;
  margin-top: .5rem;
  margin-bottom: .5rem;
  justify-content: flex-start;
}
   .shippingpostal{
    margin-left: 0rem;
    margin-right: 0rem
  }
  .shippingcity{
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .checkoutform{
    width: 90vw;
    height:45vh;    
  }
  .formquestionsection{
    width: 100%;
flex-direction: row;
margin-right: auto;
margin-left: auto;
justify-content: space-around;
  }
  .checkoutmaincontainer{
overflow: scroll;
  }
  .checkoutnewslidercontent{
    height: 200vh;
    justify-content: center;
  }
  .Orderitemimage{
    width: 2rem;
    height: 2rem;
  }
  .orderitemtitle{
    font-size: 1rem; 
  }
  .checkoutcartItemholder{
    width:50%;
  }
  .Yourorder{
    width: 100%;
  }
  .cartitemsection{
    display: none;
  }
  .Ordercartitemsection{
    justify-content: flex-start;
    width: 100%;
  }
  .mobilecartitemsection{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90vw;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
  .quantityandremovebuttonholder{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    margin-top: 1rem;
  }
  .Yourbagtitlemobile{
    display: flex;
    font-size: calc(2vw + 1rem);
    margin-top: 2rem;
  }
  .shippingmain{
    flex-direction: column;
    width: 90%;
    padding-bottom: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;    
  }
  .orderinformationholder{
    padding-right: 0rem;
    padding-bottom: 2rem;
  }
  .shippinginfocontentholder{
    width:90vw;
  }
  .finalcheckout{
    padding-bottom: 1rem !important;
  }
  .orderdetailsholder{
    height: 10rem;    
  }
  .ordersummaryproductsectiontitle{
    margin-bottom:1rem
  }
  .cartdetailsholder{
    height: 90vh;
    width: 95vw;
  }
  .cartholder{
    width: 95%;
  }
  .addreducebutton{
    width: 1.25rem;
    height: 1.5rem;
    font-size: 1rem;   
    align-items: center;
      }
      .removebutton{
        margin-top: 1.5rem;
        padding: 0rem;
      }
      .total{
        margin-top: 1.5rem;
      }
   .totalproductsectiontitle{
    font-size: calc(3vw + 1rem);
    margin-top: 1rem;
   }   
  .itemquantitydisplay{
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
  .YourCart{
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }
  .baglink{
    z-index: 10;
  }
  .Yourbagtitle{
    display: none;
  }
  .shoppingbag{
    height:5.5rem !important;
    width: 2rem !important;
  }
  .Cartitemimage{
    width: 4.5rem;
    height: 4.5rem;
  }
.totaldata{
  display: flex;
  column-span: all;
  justify-content: center;
}
  .quantityindicator{
    margin-top: 2rem;
    margin-left: 13rem;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
  }
  .producttpagemainimg{ 
    width: 20rem;
  }
.cartitemtitle{
  font-size: 1.3rem;
}
.removeitembuttonholder{
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.quantityincreaser{
  margin-left: 0rem;
  margin-right: 0rem;
}
.checkoutquantityincreaser{
  margin-left: 4rem;
  margin-right: 2rem;
}
.cartitemcolor{
  font-size: .75rem;
}
.orderitemprice{
  font-size: .75rem;
}
.orderitemsizeholder{
  width:1.5rem;
  height:1.5rem;
}
.cartitemsizeholder{
  width: 1rem;
  height: 1rem;
}

.cartitemsize{
  font-size: .75rem;
}

.cartitemprice{
  font-size: 1rem;
}
.quantitytitle{
font-size: 1rem;
}

  .heroimage1{
    display: flex;
    margin-top: 0rem;
  }
  
  .productcolumnsection{
    display: flex;
    flex-direction: column;
  }
  
  .shippingandnumberofitems{
    display: flex;   
    margin-left: 0rem;
    flex-direction: column;
    justify-content: center;
    
  }

  .shippinginfo{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 5%; 
  }
  .priceholder{
    margin-top: 5%;
  }

  .price{
    margin-top: 1rem;
    padding-bottom: .5rem;
    color: white; 
    font-family: Viga;
    font-size: calc(1rem + 1vw)
  }

  .purchasebuttonholder{
    display: flex;
    margin-top: auto;
    margin-top: 5%;
  }
  .togglebutton{
position: absolute;
right: 0;
  }
  .shoppingbag{
    height:5.5rem !important;
    width: 1.5rem !important;
    padding-right: 2rem !important;
  }

  .quantityindicator{
    margin-top: 2rem;
    margin-left: 8rem;
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
  }

  .togglebutton{
    position: absolute;
    right: 0;
      }
      .shoppingbag{
        height:5.5rem !important;
        width: 1.5rem !important;
        padding-right: 1rem !important;
      }
    
      .quantityindicator{
        margin-top:1rem;
        margin-left: 2rem;
        width: 1.5rem;
        height: 1.5rem;
        font-size: .75rem;
      }
  /* width */
::-webkit-scrollbar {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {  
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: purple;  
  border-radius: 10px;
}

.productsectiontitle{
  margin-top:1rem;
  font-size: calc(.75rem + 1vw);
}

  .producttpagemainimg{
    width: 10rem;
  }
  .productcolumnsection{
flex-direction: column;
  }
  .producttitle{
    font-size: 1.25rem;
    text-align: center;
  }
  .shippingandnumberofitems{
  margin-left: 0rem;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  }
  .productdescriptionholder{
    display: flex;
    text-align: center;
    justify-content: center;
    width: calc(10vw + 20rem);
    margin-top: .5rem;
  }
  .productcolourimage{
    width:3rem
  }
  .ProductMerchinfoholder{
display: flex;
flex-direction: column;
align-items: center;
  }
  .merchslider{
    flex-direction: column;
    width: 100vw;
    height:400%;
    align-items: center;  
    justify-content: flex-start;
    }

    .togglebutton{
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
    }

    .App-logo{
      width: 10rem;
      height: auto;
    }
    .logolink{
        margin-left:1rem;
        margin-top: 1rem;
    }
    .subtitleholdermobile{
      display: flex;
      }
  .slider{
    flex-direction: column;
    width: 100vw;
    height: 150%;
    align-items: center;  
    justify-content: flex-start;
  }
  .navcanvas{
    opacity: 1;
    width: 5rem;
    height: 5rem;
  }
  .links{
    display: none;
  }
  .scroll-horizontal{
    display: flex;
    flex-direction: column;
    overflow: auto !important;
    overflow-x: hidden !important; 
    width: 100vw !important;
  }

  .secondcontentholder{
    display: flex;
    flex-direction: column;
    flex: wrap;
    width: 100vw !important;
  }

  .overlapundercontent{
    top:19rem;
    left: 9rem;
  }
  .overlapundercontent1{
left: -2rem;
top:17rem;
  }
  .overlapundercontent2{
    left: 0rem;
top:40rem
  }
  .overlapundercontent3{
    left: 25%;
    top:50rem
  }

.canvasholder2{
    left: 0
  }
  .newslidercontent{
  overflow: hidden;
  }
  .productnewslidercontent{
    overflow: hidden;
    }
  .maincontainer{
    overflow: hidden;
  }

  .canvasholder1{
    top:2rem;
    left: 4rem;
  }

  .overlapcontent{
    top:15rem;
    left: 0;  
  }
  .mobilesubtitle{
display: flex;
width: 100%;
  }
  .subtitle{
    display: none;
  }

  .titleholder{
    display: flex;
    justify-content: center;
        }
        
    .titleholdermobile{
    display: none;
    justify-content: center;
    } 

    .herotitleholder{
      display: flex;
      margin-left: 0;
      margin-top: 2rem;
      justify-content: center;
      align-items: center;
    }

.subtitleholder{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;   
}
.workcontainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1{
  font-size: 2.5rem;
  text-align: center;
}
.workcanvas{
  width: 50vw !important;
}

.Workmainpara{
  font-size: 1rem;
 width: 25rem;
 text-align: center;
}
.workcontentholder{
  display: flex;
  width: 100vw !important;
  justify-content: center;
  align-items: center;
  margin-left: 0;
}

.Coffeework{
  max-height:40vh;
  margin-bottom: 0rem;
}
.workcontainer{
  justify-content: flex-start;
  align-items: center;
}

.overlaytextholder{
  display: flex;
  width: 100vw !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.workimgtheway1{
width: 90%;
}
.workcontenttheway1{
  height: calc(3vh + 3rem);
}
.workcontenttheway5{
  height: calc(3vh + 4rem);
}
.workcontenttheway2{
  height: calc(7vh + 5rem);
}
.workcontenttheway3{
  height: calc(7vh + 5rem);
}
.workcontenttheway4{
  height: calc(7vh + 5rem);
}
.Workmaintitle1{
font-size: 2.2rem;
text-align: center;
}
.Workmaintitle{
  font-size: 2.2rem;
}
.workimghalloween{
width: calc(10vw + 6rem);
}
.rightcontentsectiontrans{
width: 75%;
}
h2{
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
}
.rightcontentsection{
  grid-row-gap: calc(1vh + .5rem);

}
.workimgtrans1{
  justify-content: space-evenly;
  height: 55%;
  grid-row: span 1;
}
.transcindental{
height: 40vh;
}
.workimgtrans2{
  justify-content: space-evenly;
  height: 55%;
  grid-row: span 1;  
}
.arrowsectiontrans{
  max-height: 1.5rem;
}
.workimgtrans3{
  height: 5rem;  
}
.rightcontentsectiontrans{
  grid-row-gap: 0;
}
.workimgtara{
  width: calc(10vw + 8rem);
}
.workimgtara1{
  justify-content: flex-end;
}
.workimgtara2{
  justify-content: flex-end;
}
.workimgtara3{
  justify-content: flex-start;
}
.workimgtara4{
  justify-content: flex-end;
}

.taraeyes{
  width: 90%;
}
.arrowsectionhalloween{
  max-height: 1.5rem;
}
.arrowsection{
  max-height: 1.5rem;
}

.workimgtara1{
  justify-content: center;
  align-items: center;
}

#halloween1{
  height: 250px !important;
  }
#starwars1{
  height: 250px !important;
}  
#scream1{
  height: 430px !important;
}
#trans1{
  height: 330px !important;
}
.Workmaintitle2{
  margin-top: 0rem;
  text-align: center;
  font-size: 2.2rem;
}
p{
  margin-block-start: .5em;
  margin-block-end: .5em;
}
.CTAbutton{
  font-size: 1.5rem !important;
}
.buttonholder{
  width: calc(20vw + 15rem);
}
.merchcontainer{
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.herotitleholdermerch{
  margin-left: 0;
  margin-top: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.subtitlemobile{
  display: flex;
  font-size: 1rem;
  text-align: center;
  justify-content: center;
  font-family:Viga;
  width: 25rem;
}
.item2{
  margin-right: 0;
  margin-left: 0;
  
}
.item3 {
  margin-right: 0;
  margin-left: 0;
  margin-top: 5rem;
}
.merchdescription{
  font-size: 1.5rem;
}

/* about us page */

.newslidercontent{
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
}

.productnewslidercontent{
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: .5rem;
}
.purchasebuttonholder {
  margin-top: .5rem;
}
.aboutustitle{
font-size: calc(2vw + 2rem);
text-align: center;
}
.aboutustext{
  font-size: .75rem;
}
.aboutuscontentholder{
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 0rem;
}

/* Contact us page */

.contactustitle{
  font-size: calc(1vw + 2rem);
  text-align: center;
}
label{
  font-size: .75rem;
}
.radiobutton{
  font-size: .75rem;
}
input{
  font-size: 1rem;  
}
.shipforminput{
  width: 6rem;
}
.Optionselection{
  font-size: .75rem;
  width:7rem;
}
.textarea{
  font-size: 1rem;
}
.CTAbutton{
  font-size: 1rem !important;
}

.buttonholder1{
width: calc(15vw + 12rem)
}
.formholder{
width: 90vw;
}
.aboutimg{
  width: 10rem;
}

.heroimage1{
  display: flex;
  margin-top: 0rem;
}

/* Merch */

.merchimg {
  width: 80%;
  height:80%
}
.soldout{
  width: 90%;
  height:90%
}
.merchtitle{
font-size: 1.5rem;
}
.merchdescription{
  font-size: 1rem;
}
.merchitem{
  justify-content: center;
  align-items: center;
}
.svg-inline--fa {
  height: 2rem !important;
  width: 1rem !important;
  right:2rem;
  top: 2rem;
}

.Trashcan{
  top:0rem !important;
  width: 1.5rem !important;
  height: .75rem !important;
  right:0rem;
}
.overlapundercontent4{
  position:absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  top:66rem;  
  left:0;
  height: auto;   
}

.instagramlink{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 360%;
  background-color: purple;
  width: 3rem;
  height: 3rem;
  transition: all .75s ease-in-out;
  text-decoration: none;  
}

.instaicon{
position: static !important;
margin-bottom: 0px;
width: 1.5rem !important;
height: 1.5rem !important;
color: white !important;
top: 50% !important;
right: 50% !important;
left : 50% !important;
bottom : 50% !important;
text-align: center;
}  
.bewlogoholder{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 360%;
  background-color: black;
  width: 3rem;
  height: 3rem;
  transition: all .75s ease-in-out;  
}

.bewlogoimg1{
  position: absolute;
  opacity: 100%;
  width: 2rem;
  height: 2rem;
  transition: all .75s ease-in-out; 
}
.bewlogoimg2{
  position: absolute;
  opacity: 0%;
  width: 2rem;
  height: 2rem;
  transition: all .75s ease-in-out; 
}
.colorandsizesection{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
.productcolourpicker{
  width: 20rem;
  margin-top: 1rem;
}
.productsizepicker{
  width: 20rem;
  margin-top: 1rem;
}
.productsizeholder{
  width: 2.5rem;
  height: 2.5rem;
}
.productcolourimage{
  width:2.5rem;
  height: 2.5rem;
}
.shippinginfo{
  margin-top: .5rem;
}
.priceholder{
  margin-top: 1rem;
}
  .productsectiontitleleft{
    font-size:calc(.75rem + 1vw)
  }
  .shippingwarning{
    font-size: calc((.5rem + .5vw));
  }
  .price{
    margin-top: 0rem;
  }
  .dropdownmenu.open{
    display: flex;
  }
}

















/* Iphone small*/
@media(min-width:375px ) and (max-width:413px)  {
  .baglinkmobile{
    display: block;
  }
  .transcontent{
    grid-gap: 0rem;
    align-items: center;
  }
  .descriptorholder4{
    left:10%;
  }
  .descriptorholder5{
    top: 40%;
    left:-10%;
  }
  .descriptorholder2{
    left:-25%;
  }
  .descriptorholder3{
    left:-15%;
  }
  .descriptorholder{
    left: -15%
  }
.totalsummaryholder{
height: 10rem;
margin-bottom: 2rem;
}

  .shipforminput{
    width: 6rem;
  }
  .cartiteminfo{
    padding:.75rem;
  }
  .postalandstateholder{
    margin-bottom: 0rem;
    margin-top: 0rem;
  }
  .checkoutfieldsection{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

  }
  .shippingformbutton{
    margin-top: 0rem;
    margin-bottom: 1rem;
  }
  .checkoutshippinginformationtitle{
    font-size: 1.35rem !important;
  }

  .checkoutcitylabel{
    margin-left: .5rem;
  }
  .checkoutPostallabel{
    margin-left: .5rem;
  }
  .formquestionlabel{
  font-size: .65rem;
  margin-left: .5rem;
  margin-right: .5rem;
  }
  .Postallabel{
    font-size: .65rem;
  }
  .citylabel{
    font-size: .65rem;
  }
  .formquestionitem{
    display: flex;
    justify-content: space-between;
    width:21rem;
    margin-left: .25rem;
    margin-right: .25rem;
  }
.finalcheckoutdetails{
  width: 100%;
  align-items: center;
}
.checkoutNameholder{
  font-size: .65rem;
}
.paypalbuttonholders{
  margin-top: 0rem;
  margin-bottom: 4rem;
}

.paymentformholder{
justify-content: flex-start;
margin-top: 2rem;
width: 90%;
height: 0rem;
}
.checkoutinfocontentholder{
height:25rem
}
.checkoutfieldholder{
  width: 45vw;
  margin-top: .5rem;
  margin-bottom: .5rem;
  justify-content: flex-start;
}
   .shippingpostal{
    margin-left: 0rem;
    margin-right: 0rem
  }
  .shippingcity{
    margin-left: 0.5rem;
    margin-right: 0rem;
  }
  .checkoutform{
    width: 95vw;
    height:45vh;
    top:1.5rem !important;    
  }
  .formquestionsection{
    width: 100%;
flex-direction: row;
margin-right: auto;
margin-left: auto;
justify-content: space-around;
  }
  .checkoutmaincontainer{
overflow: scroll;
  }
  .checkoutnewslidercontent{
    height: 200vh;
    justify-content: center;
  }
  .Orderitemimage{
    width: 2rem;
    height: 2rem;
  }
  .orderitemtitle{
    font-size: 1rem; 
  }
  .checkoutcartItemholder{
    width:50%;
  }
  .Yourorder{
    width: 100%;
  }
  .cartitemsection{
    display: none;
  }
  .Ordercartitemsection{
    justify-content: flex-start;
    width: 100%;
  }
  .mobilecartitemsection{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 90vw;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
  .quantityandremovebuttonholder{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    margin-top: 1rem;
  }
  .Yourbagtitlemobile{
    display: flex;
    font-size: calc(2vw + 1rem);
    margin-top: 2rem;
  }
  .shippingmain{
    flex-direction: column;
    width: 90%;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .5rem !important;    
  }
  .orderinformationholder{
    padding-right: 0rem;
    padding-bottom: 1rem;
  }
  .shippingformholder{
    align-items: center;
  }
  .shippinginfocontentholder{
    width:90vw;
  }
  .finalcheckout{
    padding-bottom: 1rem !important;
  }
  .orderdetailsholder{
    height: 10rem;    
  }
  .ordersummaryproductsectiontitle{
    margin-bottom:.5rem
  }
  .cartdetailsholder{
    height: 90vh;
    width: 95vw;
  }
  .cartholder{
    width: 95%;
  }
  .addreducebutton{
    width: 1.25rem;
    height: 1.5rem;
    font-size: 1rem;   
    align-items: center;
      }
      .removebutton{
        margin-top: 1.5rem;
        padding-left: .35rem;
        padding-right: .35rem;
      }
      .total{
        margin-top: 1.5rem;
      }
   .totalproductsectiontitle{
    font-size: calc(1vw + 1rem);
    margin-top: 1rem;
   }   
  .itemquantitydisplay{
    font-size: .75rem;
    display: flex;
    align-items: center;
  }
  .ordertotal{
    font-size: calc(.75vw + .75rem)
  }
  .YourCart{
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    flex-wrap: wrap;
    align-content: space-between;
    align-items: stretch;

  }
  .baglink{
    z-index: 10;
  }
  .Yourbagtitle{
    display: none;
  }
  .shoppingbag{
    height:5.5rem !important;
    width: 2rem !important;    
  }
  .Cartitemimage{
    width: 4.5rem;
    height: 4.5rem;
  }
.totaldata{
  display: flex;
  column-span: all; 
}
  .quantityindicator{
    margin-top: 2rem;
    margin-left: 13rem;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
  }
  .producttpagemainimg{ 
    width: 20rem;
  }
.cartitemtitle{
  font-size: 1.3rem;
}
.removeitembuttonholder{
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.quantityincreaser{
  margin-left: 0rem;
  margin-right: 0rem;
}
.checkoutquantityincreaser{
  margin-left: 4rem;
  margin-right: 1.5rem;
}
.cartitemcolor{
  font-size: .75rem;
}
.orderitemprice{
  font-size: .75rem;
}
.orderitemsizeholder{
  width:1.5rem;
  height:1.5rem;
}
.cartitemsizeholder{
  width: 1rem;
  height: 1rem;
}

.cartitemsize{
  font-size: .75rem;
}

.cartitemprice{
  font-size: 1rem;
}
.quantitytitle{
font-size: 1rem;
}

  .heroimage1{
    display: flex;
    margin-top: 0rem;
  }
  
  .productcolumnsection{
    display: flex;
    flex-direction: column;
  }
  
  .shippingandnumberofitems{
    display: flex;   
    margin-left: 0rem;
    flex-direction: column;
    justify-content: center;
    
  }

  .shippinginfo{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 5%; 
  }
  .priceholder{
    margin-top: 5%;
  }

  .price{
    margin-top: 1rem;
    padding-bottom: .5rem;
    color: white; 
    font-family: Viga;
    font-size: calc(1rem + 1vw)
  }

  .purchasebuttonholder{
    display: flex;
    margin-top: auto;
    margin-top: 5%;
  }
  

  .quantityindicator{
    margin-left: 1.45rem !important;
    margin-top: 1.98rem !important;
    width: 1rem;
    height: 1rem;
    font-size: .75rem;
  }
  /* width */
::-webkit-scrollbar {
  display: none;
}

/* Track */
::-webkit-scrollbar-track {  
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: purple;  
  border-radius: 10px;
}
  .colorandsizesection{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
    .productcolourpicker{
      width: 20rem;
      margin-top: .5rem;
    }
    .productsizepicker{
      width: 20rem;
      margin-top: 1rem;
    }
    .productsizeholder{
      width: 2.5rem;
      height: 2.5rem;
    }
    
    .productcolourimage{
      width:2rem;
      height: 2rem;
    }
    .shippinginfo{
      margin-top: .5rem;
    }
    .priceholder{
      margin-top: 0rem;
    }
      .productsectiontitleleft{
        font-size:calc(.75rem + .5vw)
      }
      .shippingwarning{
        font-size: calc((.5rem + .25vw));
      }
      .price{
        margin-top: 0rem;
      }

  .productnewslidercontent{
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: .5rem;
  }
  .purchasebuttonholder {
    margin-top: .5rem;
  }
  .merchslider{
    flex-direction: column;
    width: 100vw;
    height:470%;
    align-items: center;  
    justify-content: flex-start;
    }
    .links{
      display: none;
    }
  .App-logo{
      width: 10rem;
      height: auto;
    }
    .logolink{
        margin-left:1rem;
    }
.togglebutton{
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  top:1rem;

}

.subtitleholdermobile{
  display: flex;
  }
.dropdownmenu{
  display: flex;
}
#dark.expand{
  height: 100vh;
  width: 100vw;
  top: 2rem;   
}
.dropdownmenu.open{
  top: 2rem; 
}
.navcanvas{
  width: 4rem;
  height: 4rem;
  opacity: 1;
}
.dropdownmenu a{
font-size: 2rem;
}
.svg-inline--fa {
  height: 2rem !important;
  width: 1rem !important;
  right:2rem;
  top: 1.5rem;
}
.navbar{
  align-items: center;
}
  .slider{
    flex-direction: column;
    width: 100vw;
    height: 150%;
    align-items: center;  
    justify-content: flex-start;
  }

  .scroll-horizontal{
    display: flex;
    flex-direction: column;
    overflow: auto !important;
    overflow-x: hidden !important; 
    width: 100vw !important;
  }

  .secondcontentholder{
    display: flex;
    flex-direction: column;
    flex: wrap;
    width: 100vw !important;
  }

  .overlapundercontent{
    top:19rem;
    left: 9rem;
  }
  .overlapundercontent1{
left: -2rem;
top:17rem;
  }
  .overlapundercontent2{
    left: 0rem;
top:40rem
  }
  .overlapundercontent3{
    left: 25%;
    top:50rem
  }

.canvasholder2{
    left: 0
  }
  .newslidercontent{
  overflow: hidden;
  }

  .productnewslidercontent{
    overflow: hidden;
    }
  .maincontainer{
    overflow: hidden;
  }

  .canvasholder1{
    top:2rem;
    left: 4rem;
  }

  .overlapcontent{
    top:15rem;
    left: 0;  
  }
  .mobilesubtitle{
display: flex;
width: 100%;
  }
  .subtitle{
    display: none;
  }

  .titleholder{
    display: flex;
    justify-content: center;
    font-size: 2rem;
        }
        
    .titleholdermobile{
    display: none;
    justify-content: center;
    } 

    .herotitleholder{
      display: flex;
      margin-left: 0;
      margin-top: 2rem;
      justify-content: center;
      align-items: center;
    }

.subtitleholder{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  margin-top: 0rem;  
}
.workcontainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1{
  font-size: calc(4vw + 3rem);
  text-align: center;
}
.workcanvas{
  width: 50vw !important;
}
.merchimg {
  width: 80%;
  height: 80%;
}
.Workmainpara{
  font-size: 0.75rem;
 width: 20rem;
 text-align: center;
}
.workcontentholder{
  display: flex;
  width: 100vw !important;
  justify-content: center;
  align-items: center;
  margin-left: 0;
}

.Coffeework{
  max-height:40vh;
  margin-bottom: 0rem;
}
.workcontainer{
  justify-content: flex-start;
  align-items: center;
}

.overlaytextholder{
  display: flex;
  width: 100vw !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.workimgtheway1{
width: 90%;
}
.workcontenttheway1{
  height: calc(3vh + 3rem);
}
.workcontenttheway5{
  height: calc(3vh + 4rem);
}
.workcontenttheway2{
  height: calc(7vh + 5rem);
}
.workcontenttheway3{
  height: calc(7vh + 5rem);
}
.workcontenttheway4{
  height: calc(7vh + 5rem);
}
.Workmaintitle1{
font-size: 2rem;
text-align: center;
}
.Workmaintitle{
  font-size: 2rem;
}
.workimghalloween{
width: calc(10vw + 6rem);
}
.rightcontentsectiontrans{
width: 75%;
}
h2{
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
}
.rightcontentsection{
  grid-row-gap: calc(1vh + .5rem);

}
.workimgtrans1{
  justify-content: space-evenly;
  grid-row: span 1;
  height: 50%;
}
.transcindental{
height: 35vh;
}
.workimgtrans2{
  justify-content: space-evenly;
  grid-row: span 1;
  height: 50%;  
}
.arrowsectiontrans{
  max-height: 1.5rem;
}
.togglebutton{
  position: absolute;
  right: 0;
  top:0.2rem
    }
.shoppingbag{
  height:5.5rem !important;
  width: 1rem !important;
  padding-right: 2rem !important;
  top:0rem;
}

.workimgtrans3{
  height: 5rem;  
}
.rightcontentsectiontrans{
  grid-row-gap: 0;
}
.workimgtara{
  width: calc(5vw + 8rem);
}
.workimgtara1{
  justify-content: flex-end;
}
.workimgtara2{
  justify-content: flex-end;
}
.workimgtara3{
  justify-content: flex-start;
}
.workimgtara4{
  justify-content: flex-end;
}

.taraeyes{
  width: 85%;
}
.arrowsectionhalloween{
  max-height: 1.2rem;
}
.arrowsection{
  max-height: 1.2rem;
}
.workimgtara1{
  justify-content: center;
  align-items: center;
}

#halloween1{
  height: 225px !important;
  }
#starwars1{
  height: 225px !important;
}  
#scream1{
  height: 225px !important;
}
#trans1{
  height: 305px !important;
}
.Workmaintitle2{
  font-size: 2rem;
  margin-top: 0rem;
  text-align: center;
}
p{
  margin-block-start: .5em;
  margin-block-end: .5em;
}
.CTAbutton{
  font-size: 1.5rem !important;
}
.buttonholder{
  width: calc(20vw + 15rem);
}
.merchcontainer{
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.herotitleholdermerch{
  margin-left: 0;
  margin-top: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.subtitlemobile{
  display: flex;
  font-size: 0.75rem;
  text-align: center;
  justify-content: center;
  font-family:Viga;
  width: 20rem;
}



.item2{
  margin-right: 0;
  margin-left: 0;
  margin-top: 5rem;  
}
.item3 {
  margin-right: 0;
  margin-left: 0;
  margin-top: 5rem;  
}
.merchdescription{
  font-size: 0.75rem;
  width:70%
}
.merchitem{
  align-items: center;
  }

.soldout{
  width: 90%;
  height:90%
}
/* about us page */

.newslidercontent{
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
}

.productnewslidercontent{
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: .5rem;
}
.aboutustitle{
font-size: calc(2vw + 2rem);
text-align: center;
}
.aboutustext{
  font-size: 0.75rem;
}
.aboutuscontentholder{
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 0rem;
}
.aboutimg{
  width: 10rem;
}

.heroimage1{
  display: flex;
  margin-top: 0rem;
}

/* Contact us page */

.contactustitle{
  font-size: calc(1vw + 2rem);
  text-align: center;
}
label{
  font-size: 1rem;
}
.radiobutton{
  font-size: .75rem;
}
input{
  font-size: 1rem;
  
}
.Optionselection{
  width: 6rem;
  font-size: .65rem;
}
.textarea{
  font-size: 1rem;
}
.CTAbutton{
  font-size: 1rem !important;
}

.buttonholder1{
width: calc(15vw + 12rem)
}
.formholder{
width: 90vw;
}

.merchtitle{
  font-size: 1.5rem;
  }

  
.overlapundercontent4{
  position:absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  top:66rem;  
  left:0;
  height: auto;   
}

.instagramlink{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 360%;
  background-color: purple;
  width: 3rem;
  height: 3rem;
  transition: all .75s ease-in-out;
  text-decoration: none;  
}

.instaicon{
position: static !important;
margin-bottom: 0px;
width: 1.5rem !important;
height: 1.5rem !important;
color: white !important;
top: 50% !important;
right: 50% !important;
left : 50% !important;
bottom : 50% !important;
text-align: center;
}  
.bewlogoholder{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 360%;
  background-color: black;
  width: 3rem;
  height: 3rem;
  transition: all .75s ease-in-out;  
}

.bewlogoimg1{
  position: absolute;
  opacity: 100%;
  width: 2rem;
  height: 2rem;
  transition: all .75s ease-in-out; 
}
.bewlogoimg2{
  position: absolute;
  opacity: 0%;
  width: 2rem;
  height: 2rem;
  transition: all .75s ease-in-out; 
}
.productsectiontitle{
  margin-top:1rem;
  font-size: calc(.75rem + 1vw);
}

  .producttpagemainimg{
    width: 10rem;
  }
  .productcolumnsection{
flex-direction: column;
  }
  .producttitle{
    font-size: 1.15rem;
    text-align: center;
  }
  .shippingandnumberofitems{
  flex-direction: row;
  margin-left: 0rem;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  text-align: left;
  margin-top: 1rem;
  }
  .productdescriptionholder{
    display: flex;
    text-align: center;
    justify-content: center;
    width: calc(10vw + 20rem);
    margin-top: .5rem;
  }
  .productcolourimage{
    width:2rem;
    height:2rem
  }
  .ProductMerchinfoholder{
display: flex;
flex-direction: column;
align-items: center;
  }

  .Trashcan{
    top:0rem !important;
    width: 1.25rem !important;
    height: .75rem !important;
    right:0rem !important;
  }
  .checkoutPostallabel{
font-size: .65rem;
  }
  .checkoutcitylabel{
    font-size: .65rem;
  }
  
}

















/* Iphone super small*/
@media(max-width:374px) {
 
  .baglinkmobile{
    display: block;
  }
  .totalsummaryholder{
    height: 10rem;
    margin-bottom: 2rem;
    }
    
      .shipforminput{
        width: 6rem;
      }
      .cartiteminfo{
        padding:.75rem;
      }
      .postalandstateholder{
        margin-bottom: 0rem;
        margin-top: 0rem;
      }
      .checkoutfieldsection{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    
      }
      .shippingformbutton{
        margin-top: 0rem;
        margin-bottom: 1rem;
      }
      .checkoutshippinginformationtitle{
        font-size: 1.35rem !important;
      }
    
      .checkoutcitylabel{
        margin-left: .5rem;
      }
      .checkoutPostallabel{
        margin-left: .5rem;
      }
      .formquestionlabel{
      font-size: .65rem;
      margin-left: .5rem;
      margin-right: .5rem;
      }
      .Postallabel{
        font-size: .65rem;
      }
      .citylabel{
        font-size: .65rem;
      }
      .formquestionitem{
        display: flex;
        justify-content: space-between;
        width:21rem;
        margin-left: .5rem;
        margin-right: .5rem;
      }
    .finalcheckoutdetails{
      width: 100%;
      align-items: center;
    }
    .checkoutNameholder{
      font-size: .65rem;
    }
    .paypalbuttonholders{
      margin-top: 0rem;
      margin-bottom: 4rem;
    }
    
    .paymentformholder{
    justify-content: flex-start;
    margin-top: 2rem;
    width: 90%;
    height: 0rem;
    }
    .checkoutinfocontentholder{
    height:25rem
    }
    .checkoutfieldholder{
      width: 45vw;
      margin-top: .5rem;
      margin-bottom: .5rem;
      justify-content: flex-start;
    }
       .shippingpostal{
        margin-left: 0rem;
        margin-right: 0rem
      }
      .shippingcity{
        margin-left: 0.5rem;
        margin-right: 0rem;
      }
      .checkoutform{
        width: 95vw;
        height:45vh;
        top:1.5rem !important;    
      }
      .formquestionsection{
        width: 100%;
    flex-direction: row;
    margin-right: auto;
    margin-left: auto;
    justify-content: space-around;
      }
      .checkoutmaincontainer{
    overflow: scroll;
      }
      .checkoutnewslidercontent{
        height: 200vh;
        justify-content: center;
      }
      .Orderitemimage{
        width: 2rem;
        height: 2rem;
      }
      .orderitemtitle{
        font-size: 1rem; 
      }
      .checkoutcartItemholder{
        width:50%;
      }
      .Yourorder{
        width: 100%;
      }
      .cartitemsection{
        display: none;
      }
      .Ordercartitemsection{
        justify-content: flex-start;
        width: 100%;
      }
      .mobilecartitemsection{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 90vw;
        padding-top: 1rem;
        padding-bottom: 2rem;
      }
      .quantityandremovebuttonholder{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
        margin-top: 1rem;
      }
      .Yourbagtitlemobile{
        display: flex;
        font-size: calc(2vw + 1rem);
        margin-top: 2rem;
      }
      .shippingmain{
        flex-direction: column;
        width: 90%;
        padding-bottom: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: .5rem !important;    
      }
      .orderinformationholder{
        padding-right: 0rem;
        padding-bottom: 1rem;
      }
      .shippingformholder{
        align-items: center;
      }
      .shippinginfocontentholder{
        width:90vw;
      }
      .finalcheckout{
        padding-bottom: 1rem !important;
      }
      .orderdetailsholder{
        height: 10rem;    
      }
      .ordersummaryproductsectiontitle{
        margin-bottom:.5rem
      }
      .cartdetailsholder{
        height: 90vh;
        width: 95vw;
      }
      .cartholder{
        width: 95%;
      }
      .addreducebutton{
        width: 1.25rem;
        height: 1.5rem;
        font-size: 1rem;   
        align-items: center;
          }
          .removebutton{
            margin-top: 1.5rem;
            padding-left: .35rem;
            padding-right: .35rem;
          }
          .total{
            margin-top: 1.5rem;
          }
       .totalproductsectiontitle{
        font-size: calc(1vw + .75rem);
        margin-top: 1rem;
       }   
      .itemquantitydisplay{
        font-size: .75rem;
        display: flex;
        align-items: center;
      }
      .YourCart{
        display: flex;
        flex-direction: column-reverse;
        height: auto;
        flex-wrap: wrap;
        align-content: space-between;
        align-items: stretch;
    
      }
      .baglink{
        z-index: 10;
      }
      .Yourbagtitle{
        display: none;
      }

      .Cartitemimage{
        width: 4.5rem;
        height: 4.5rem;
      }
    .totaldata{
      display: flex;
      column-span: all; 
    }
    .quantityindicator{
      margin-left: -1.5rem !important;
      margin-top: 0rem !important;
      width: 1rem;
      height: 1rem;
      font-size: .75rem;
    }
   
      .producttpagemainimg{ 
        width: 20rem;
      }
    .cartitemtitle{
      font-size: 1.3rem;
    }
    .removeitembuttonholder{
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
    .quantityincreaser{
      margin-left: 0rem;
      margin-right: 0rem;
    }
    .checkoutquantityincreaser{
      margin-left: 4rem;
      margin-right: 1rem;
    }
    .cartitemcolor{
      font-size: .75rem;
    }
    .orderitemprice{
      font-size: .75rem;
    }
    .orderitemsizeholder{
      width:1.5rem;
      height:1.5rem;
    }
    .cartitemsizeholder{
      width: 1rem;
      height: 1rem;
    }
    
    .cartitemsize{
      font-size: .75rem;
    }
    
    .cartitemprice{
      font-size: 1rem;
    }
    .quantitytitle{
    font-size: 1rem;
    }
    
      .heroimage1{
        display: flex;
        margin-top: 0rem;
      }
      
      .productcolumnsection{
        display: flex;
        flex-direction: column;
      }
      
      .shippingandnumberofitems{
        display: flex;   
        margin-left: 0rem;
        flex-direction: column;
        justify-content: center;
        
      }
    
      .shippinginfo{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        margin-top: 5%; 
      }
      .priceholder{
        margin-top: 5%;
      }
    
      .price{
        margin-top: 1rem;
        padding-bottom: .5rem;
        color: white; 
        font-family: Viga;
        font-size: calc(1rem + 1vw)
      }
    
      .purchasebuttonholder{
        display: flex;
        margin-top: auto;
        margin-top: 5%;
      }
      
    
      .quantityindicator{
        margin-left: -1.5rem !important;
        margin-top: 0rem !important;
        width: 1rem;
        height: 1rem;
        font-size: .75rem;
      }
     
      /* width */
    ::-webkit-scrollbar {
      display: none;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {  
      border-radius: 10px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: purple;  
      border-radius: 10px;
    }
      .colorandsizesection{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        }
        .productcolourpicker{
          width: 20rem;
          margin-top: .5rem;
        }
        .productsizepicker{
          width: 20rem;
          margin-top: 1rem;
        }
        .productsizeholder{
          width: 2.5rem;
          height: 2.5rem;
        }
        
        .productcolourimage{
          width:2rem;
          height: 2rem;
        }
        .shippinginfo{
          margin-top: .5rem;
        }
        .priceholder{
          margin-top: 0rem;
        }
          .productsectiontitleleft{
            font-size:calc(.75rem + .5vw)
          }
          .shippingwarning{
            font-size: calc((.5rem + .25vw));
          }
          .price{
            margin-top: 0rem;
          }
    
      .productnewslidercontent{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: .5rem;
      }
      .purchasebuttonholder {
        margin-top: .5rem;
      }
  .productsectiontitle{
    margin-top:1rem;
    font-size: calc(.75rem + 1vw);
  }
  
    .producttpagemainimg{
      width: 10rem;
    }
    .productcolumnsection{
  flex-direction: column;
    }
    .producttitle{
      font-size: 1.25rem;
      text-align: center;
    }
    .shippingandnumberofitems{
    margin-left: 0rem;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    }
    .productdescriptionholder{
      display: flex;
      text-align: center;
      justify-content: center;
      width: calc(10vw + 20rem);
      margin-top: .5rem;
    }
    .productcolourimage{
      width:3rem
    }
    .ProductMerchinfoholder{
  display: flex;
  flex-direction: column;
  align-items: center;
    }
  .merchslider{
    flex-direction: column;
    width: 100vw;
    height:470%;
    align-items: center;  
    justify-content: flex-start;
    }
    .links{
      display: none;
    }
  .App-logo{
      width: 10rem;
      height: auto;
    }
    .logolink{
        margin-left:1rem;
    }
.togglebutton{
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  top:1rem;

}

.subtitleholdermobile{
  display: flex;
  }
.dropdownmenu{
  display: flex;
}
#dark.expand{
  height: 100vh;
  width: 100vw;
  top: 2rem;   
}
.dropdownmenu.open{
  top: 2rem; 
}
.navcanvas{
  width: 4rem;
  height: 4rem;
  opacity: 1;
}
.dropdownmenu a{
font-size: 2rem;
}
.svg-inline--fa {
  height: 2rem !important;
  width: 1rem !important;
  right:2rem;
  top: 1.5rem;
}
.navbar{
  align-items: center;
}
  .slider{
    flex-direction: column;
    width: 100vw;
    height: 150%;
    align-items: center;  
    justify-content: flex-start;
  }

  .scroll-horizontal{
    display: flex;
    flex-direction: column;
    overflow: auto !important;
    overflow-x: hidden !important; 
    width: 100vw !important;
  }

  .secondcontentholder{
    display: flex;
    flex-direction: column;
    flex: wrap;
    width: 100vw !important;
  }

  .overlapundercontent{
    top:19rem;
    left: 9rem;
  }
  .overlapundercontent1{
left: -2rem;
top:17rem;
  }
  .overlapundercontent2{
    left: 0rem;
top:40rem
  }
  .overlapundercontent3{
    left: 25%;
    top:50rem
  }

.canvasholder2{
    left: 0
  }
  .newslidercontent{
  overflow: hidden;
  }
  .productnewslidercontent{
    overflow: hidden;
    }
  .maincontainer{
    overflow: hidden;
  }

  .canvasholder1{
    top:2rem;
    left: 4rem;
  }

  .overlapcontent{
    top:15rem;
    left: 0;  
  }
  .mobilesubtitle{
display: flex;
width: 100%;
  }
  .subtitle{
    display: none;
  }

  .titleholder{
    display: flex;
    justify-content: center;
    font-size: 2rem;
        }
        
    .titleholdermobile{
    display: none;
    justify-content: center;
    } 

    .herotitleholder{
      display: flex;
      margin-left: 0;
      margin-top: 2rem;
      justify-content: center;
      align-items: center;
    }

.subtitleholder{
  display: none;
  justify-content: center;
  align-items: center;
  width: 85%;
  margin-top: 0rem;  
}
.workcontainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1{
  font-size: calc(4vw + 3rem);
  text-align: center;
}
.workcanvas{
  width: 50vw !important;
}
.merchimg {
  width: 80%;
  height: 80%;
}
.Workmainpara{
  font-size: 0.75rem;
 width: 20rem;
 text-align: center;
}
.workcontentholder{
  display: flex;
  width: 100vw !important;
  justify-content: center;
  align-items: center;
  margin-left: 0;
}

.Coffeework{
  max-height:40vh
}
.workcontainer{
  justify-content: flex-start;
  align-items: center;
}

.overlaytextholder{
  display: flex;
  width: 100vw !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.workimgtheway1{
width: 90%;
}
.workcontenttheway1{
  height: calc(3vh + 3rem);
}
.workcontenttheway5{
  height: calc(3vh + 4rem);
}
.workcontenttheway2{
  height: calc(7vh + 5rem);
}
.workcontenttheway3{
  height: calc(7vh + 5rem);
}
.workcontenttheway4{
  height: calc(7vh + 5rem);
}
.Workmaintitle1{
font-size: 2rem;
text-align: center;
}
.Workmaintitle{
  font-size: 2.5rem;
}
.workimghalloween{
width: calc(10vw + 6rem);
}
.rightcontentsectiontrans{
width: 75%;
}
h2{
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
}
.rightcontentsection{
  grid-row-gap: calc(1vh + .5rem);

}
.workimgtrans1{
  justify-content: space-evenly;
  height: 50%;
}
.transcindental{
height: 35vh;
}
.workimgtrans2{
  justify-content: space-evenly;
  height: 50%;  
}
.arrowsectiontrans{
  max-height: 1.5rem;
}
.workimgtrans3{
  height: 5rem;  
}
.rightcontentsectiontrans{
  grid-row-gap: 0;
}
.workimgtara{
  width: calc(5vw + 8rem);
}
.workimgtara1{
  justify-content: flex-end;
}
.workimgtara2{
  justify-content: flex-end;
}
.workimgtara3{
  justify-content: flex-start;
}
.workimgtara4{
  justify-content: flex-end;
}

.taraeyes{
  width: 85%;
}
.arrowsectionhalloween{
  max-height: 1.2rem;
}
.arrowsection{
  max-height: 1.2rem;
}
.workimgtara1{
  justify-content: center;
  align-items: center;
}

#halloween1{
  height: 225px !important;
  }
#starwars1{
  height: 225px !important;
}  
#scream1{
  height: 225px !important;
}
#trans1{
  height: 305px !important;
}
.Workmaintitle2{
  font-size: 2rem;
  margin-top: 0rem;
  text-align: center;
}
p{
  margin-block-start: .5em;
  margin-block-end: .5em;
}
.CTAbutton{
  font-size: 1.5rem !important;
}
.buttonholder{
  width: calc(20vw + 15rem);
}
.merchcontainer{
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.herotitleholdermerch{
  margin-left: 0;
  margin-top: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.subtitlemobile{
  display: flex;
  font-size: 0.75rem;
  text-align: center;
  justify-content: center;
  font-family:Viga;
  width: 20rem;
}



.item2{
  margin-right: 0;
  margin-left: 0;
  margin-top: 5rem;  
}
.item3 {
  margin-right: 0;
  margin-left: 0;
  margin-top: 5rem;  
}
.merchdescription{
  font-size: 0.75rem;
  width:70%
}
.merchitem{
  align-items: center;
  }

.soldout{
  width: 90%;
  height:90%
}
/* about us page */

.newslidercontent{
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
}

.productnewslidercontent{
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
}
.aboutustitle{
font-size: calc(2vw + 2rem);
text-align: center;
}
.aboutustext{
  font-size: 0.75rem;
}
.aboutuscontentholder{
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 0rem;
}
.aboutimg{
  width: 10rem;
}

.heroimage1{
  display: flex;
  margin-top: 0rem;
}

/* Contact us page */

.contactustitle{
  font-size: calc(1vw + 2rem);
  text-align: center;
}
label{
  font-size: 1rem;
}
.radiobutton{
  font-size: .75rem;
}
input{
  font-size: 1rem; 
}
.Optionselection{
  font-size: .65rem;
  width: 6rem;
}
.textarea{
  font-size: 1rem;
}
.CTAbutton{
  font-size: 1rem !important;
}

.buttonholder1{
width: calc(15vw + 12rem)
}
.formholder{
width: 90vw;
}

.merchtitle{
  font-size: 1.5rem;
  }

  
.overlapundercontent4{
  position:absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 4;
  top:66rem;  
  left:0;
  height: auto;   
}

.instagramlink{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 360%;
  background-color: purple;
  width: 3rem;
  height: 3rem;
  transition: all .75s ease-in-out;
  text-decoration: none;  
}

.instaicon{
position: static !important;
margin-bottom: 0px;
width: 1.5rem !important;
height: 1.5rem !important;
color: white !important;
top: 50% !important;
right: 50% !important;
left : 50% !important;
bottom : 50% !important;
text-align: center;
}  
.bewlogoholder{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 360%;
  background-color: black;
  width: 3rem;
  height: 3rem;
  transition: all .75s ease-in-out;  
}

.bewlogoimg1{
  position: absolute;
  opacity: 100%;
  width: 2rem;
  height: 2rem;
  transition: all .75s ease-in-out; 
}
.bewlogoimg2{
  position: absolute;
  opacity: 0%;
  width: 2rem;
  height: 2rem;
  transition: all .75s ease-in-out; 
}

.shoppingbag{ 
  top: 0rem !important;
  right:4rem   
}
.Trashcan{
  top:0rem !important;
  width: 1.5rem !important;
  height: .75rem !important;
  right:0rem;
}
.ordertotal{
  font-size: calc(.75vw + .75rem)
}

}